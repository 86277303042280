import * as React from 'react'

import { t } from 'i18next'
import { Legend } from 'recharts'

import { Box, Flex, Stack, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Spacing,
  Typography,
  UseThemeReturn,
} from '@enechain/ecloud-designsystem'

type Props = {
  theme: UseThemeReturn
}

/**
 * @memo
 * recharts の render timing の関係で renderChartLender として export している
 */
export const renderChartLegend: React.FC<Props> = ({ theme }) => {
  return (
    <Legend
      align="right"
      verticalAlign="top"
      content={({ payload }): React.ReactElement => {
        return (
          <Box
            width="240px"
            marginRight={Spacing[2]}
            marginLeft={Spacing[8]}
            borderWidth="1px"
            borderStyle="solid"
            borderColor={theme.semanticTokens.colors.Border.midEmphasis}
            borderRadius={BorderRadius.radiiMd}
          >
            <Flex
              justifyContent="center"
              padding={Spacing[2]}
              backgroundColor={theme.semanticTokens.colors.Surface.secondary}
            >
              <Text {...Typography.headerSm}>
                {t('domain:model.carbon_index.column.product')}
              </Text>
            </Flex>
            <Stack padding={Spacing[2]} spacing={Spacing[2]}>
              {payload?.map((item, index) => (
                <Flex
                  key={`item-${index}`}
                  alignItems="center"
                  gap={Spacing[1]}
                >
                  <Box
                    flexShrink={0}
                    width="16px"
                    height="16px"
                    backgroundColor={item.color}
                  />
                  {item.value}
                </Flex>
              ))}
            </Stack>
          </Box>
        )
      }}
      layout="vertical"
    />
  )
}
