import * as React from 'react'

import {
  NumberInput as ChakraNumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  StyleProps,
  forwardRef,
} from '@chakra-ui/react'

type Props = {
  value: string
  onChange: (value: string) => void
  min?: number
  max?: number
  width?: StyleProps['width']
  isError?: boolean
  isDisabled?: boolean
}

const NumberInput = forwardRef<Props, 'input'>(function FormNumberInput(
  { value, onChange, min, max, width, isError = false, isDisabled },
  inputRef,
) {
  return (
    <ChakraNumberInput
      ref={inputRef}
      width={width}
      isDisabled={isDisabled}
      isInvalid={isError}
      max={max}
      min={min}
      onChange={onChange}
      value={value}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </ChakraNumberInput>
  )
})

export default React.memo(NumberInput)
