// 注文
import { t } from 'i18next'

export const PATH_ORDER_BOOK = {
  path: '/',
  name: t('common:title.list', { value: t('domain:model.order.title') }),
}

// 新規注文作成
export const PATH_CREATE_ORDER = {
  path: '/order/create',
  name: t('common:title.create', { value: t('domain:model.order.title') }),
}

// 履歴一覧
export const PATH_HISTORY = {
  path: '/history',
  name: t('trader:shared.history'),
}

// NFC・履歴・注文（/historyにリダイレクトする
export const PATH_HISTORY_NFC_ORDER = {
  path: '/history/nfc/order',
  name: t('domain:model.order.title'),
}

// NFC・履歴・注文・詳細
export const PATH_HISTORY_NFC_ORDER_DETAIL = {
  path: '/history/nfc/order/:id',
  name: t('common:title.detail', { value: t('domain:model.order.title') }),
}

// JCredit・履歴・注文（/historyにリダイレクトする
export const PATH_HISTORY_JCREDIT_ORDER = {
  path: '/history/jcredit/order',
  name: t('domain:model.order.title'),
}

// JCredit・履歴・注文・詳細
export const PATH_HISTORY_JCREDIT_ORDER_DETAIL = {
  path: '/history/jcredit/order/:id',
  name: t('common:title.detail', { value: t('domain:model.order.title') }),
}

// カーボンインデックス
export const PATH_CARBON_INDEX = {
  path: '/carbon-index',
  name: t('trader:shared.carbon_index'),
}

// 審議会レポート
export const PATH_INSTITUTION = {
  path: '/institution-report',
  name: t('domain:model.institution_report.title'),
}

export const PATH_INSTITUTION_DETAIL = {
  path: '/institution-report/:id',
  name: t('common:title.detail', {
    value: t('domain:model.institution_report.title'),
  }),
}

// ニュースレター
export const PATH_NEWSLETTER = {
  path: '/newsletter',
  name: t('domain:model.newsletter.title'),
}

export const PATH_NEWSLETTER_DETAIL = {
  path: '/newsletter/:id',
  name: t('common:title.detail', {
    value: t('domain:model.newsletter.title'),
  }),
}

// Feature Flag
export const PATH_FLAG_SETTING = {
  path: '/flag-setting',
  name: t('broker:shared.feature_setting'),
}
