import { useEffect, useState } from 'react'

import { useQuery } from '@connectrpc/connect-query'
import { useMutation as rawUseMutation } from '@tanstack/react-query'

import { getInstitutionReportDownloadSignedUrlForCustomer } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service-Service_connectquery'
import { GetInstitutionReportDownloadSignedUrlForCustomerRequest } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service_pb'
import { INITIAL_FILE_URL, useErrorToast } from '@enechain/jcex-ui'

import {
  useReportMutations,
  useReportQueries,
} from '~/trader/globalStates/reportState'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  id: Maybe<string>
}

type Result = {
  pdfUrl: Maybe<string>
  isLoading: boolean
}

export function useInstitutionReport({ id }: Params): Result {
  const recoilReportState = useReportQueries()
  const { setReportState } = useReportMutations()
  const [pdfUrl, setPdfUrl] = useState<Maybe<string>>(
    recoilReportState?.find((item) => item.id === id)?.url,
  )

  const { showToast: showErrorToast } = useErrorToast({
    toastId: 'useInstitutionDownload/error',
  })

  const downloadMutation = rawUseMutation({
    mutationFn: ({ url }: { url: string }) =>
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/pdf' },
      }),
  })

  const { data, isPending } = useQuery(
    getInstitutionReportDownloadSignedUrlForCustomer,
    new GetInstitutionReportDownloadSignedUrlForCustomerRequest({
      institutionReportId: id,
    }),
    {
      retry: 0,
      enabled: pdfUrl === undefined,
      throwOnError: (error) => {
        showErrorToast({ toastMessage: error.message })
        return true
      },
    },
  )

  useEffect(() => {
    if (
      data?.signedUrl?.url !== undefined &&
      pdfUrl === undefined &&
      id !== undefined
    ) {
      setPdfUrl(INITIAL_FILE_URL)
      downloadMutation
        .mutateAsync({ url: data?.signedUrl?.url })
        .then(async (response) => {
          const blob = await response.blob()
          const url = URL.createObjectURL(blob)
          setPdfUrl(url)
          setReportState({ id, url })
        })
        .catch((error: Error) =>
          showErrorToast({ toastMessage: error.message }),
        )
    }
  }, [
    downloadMutation,
    showErrorToast,
    isPending,
    data,
    pdfUrl,
    id,
    setReportState,
  ])

  return {
    pdfUrl,
    isLoading: isPending,
  }
}
