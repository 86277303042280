import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { BorderRadius, Spacing, useTheme } from '@enechain/ecloud-designsystem'
import { RenewableEnergyAndTrackingStatus_Type } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import { appColors } from '../../../constants/colors'

type Props = {
  type: RenewableEnergyAndTrackingStatus_Type
}

const RenewableEnergyAndTrackingBadge: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation('domain')
  const theme = useTheme()
  const isRenewable =
    type ===
      RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING ||
    type === RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY
  const isTracking =
    type === RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING

  return (
    <Flex gap={Spacing[2]} overflow="hidden">
      <Text
        flexGrow={1}
        display="inline-block"
        overflow="hidden"
        color={
          isRenewable
            ? theme.semanticTokens.colors.Text.postiveMid
            : theme.semanticTokens.colors.Text.disabled
        }
        borderRadius={BorderRadius.radiiBase}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        backgroundColor={
          // todo: color token 刷新後見直し
          isRenewable
            ? appColors.natureGreenMid
            : theme.semanticTokens.colors.Surface.quaternary
        }
        paddingX={Spacing[1]}
        paddingY={Spacing[0.5]}
      >
        {t('model.order.column.nfc.renewable_energy')}
      </Text>
      <Text
        flexGrow={1}
        display="inline-block"
        overflow="hidden"
        color={
          // todo: color token 刷新後見直し
          isTracking ? '#0c00c5' : theme.semanticTokens.colors.Text.disabled
        }
        borderRadius={BorderRadius.radiiBase}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        backgroundColor={
          // todo: color token 刷新後見直し
          isTracking
            ? appColors.aquaBlueMid
            : theme.semanticTokens.colors.Surface.quaternary
        }
        paddingX={Spacing[1]}
        paddingY={Spacing[0.5]}
      >
        {t('model.order.column.nfc.tracking')}
      </Text>
    </Flex>
  )
}

export default React.memo(RenewableEnergyAndTrackingBadge)
