import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, Text } from '@chakra-ui/react'

import {
  FlexTableCell,
  FlexTableRow,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'

import { JCreditOrderListColumnKey } from '~/trader/pages/OrderBookList/components/JCredit/ListTable/columnStyles'

export const JCreditOrderBookListTableHeaderRow: React.FC = () => {
  const { t } = useTranslation(['common', 'domain'])
  const theme = useTheme()

  return (
    <FlexTableRow>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="accordion" />
      <FlexTableCell<JCreditOrderListColumnKey>
        columnKey="deliveryFiscalYear"
        contents={{ text: t('domain:model.order.column.delivery_fiscal_year') }}
      />
      <FlexTableCell<JCreditOrderListColumnKey>
        columnKey="productName"
        contents={{
          text: t('domain:model.order.column.j_credit.product'),
        }}
      />
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="bidVolume">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={theme.semanticTokens.colors.Text.bid}
        >
          <Box height="18px" />
          <Text {...Typography.textLabel}>{t('common:unit.t_co2')}</Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="bidUnitPrice">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={theme.semanticTokens.colors.Text.bid}
        >
          <Text {...Typography.textLabel}>
            {t('domain:shared.position.bid')}
          </Text>
          <Text {...Typography.textLabel}>
            {t('common:unit.yen_per_t_co2')}
          </Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="offerUnitPrice">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={theme.semanticTokens.colors.Text.offer}
        >
          <Text {...Typography.textLabel}>
            {t('domain:shared.position.offer')}
          </Text>
          <Text {...Typography.textLabel}>
            {t('common:unit.yen_per_t_co2')}
          </Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="offerVolume">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={theme.semanticTokens.colors.Text.offer}
        >
          <Box height="18px" />
          <Text {...Typography.textLabel}>{t('common:unit.t_co2')}</Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="lastUnitPrice">
        <Flex
          flexDirection="column"
          overflow="hidden"
          color={theme.semanticTokens.colors.Text.midEmphasis}
        >
          <Text {...Typography.textLabel}>
            {t('domain:shared.position.last')}
          </Text>
          <Text {...Typography.textLabel}>
            {t('common:unit.yen_per_t_co2')}
          </Text>
        </Flex>
      </FlexTableCell>
      <FlexTableCell<JCreditOrderListColumnKey> columnKey="copyOrderAction" />
    </FlexTableRow>
  )
}
