import * as React from 'react'

import { useNavigate } from 'react-router-dom'

import { Grid } from '@chakra-ui/react'

import {
  Loading,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { NotFound } from '@enechain/jcex-ui'

import { ReportCard } from '~/trader/components/features/ReportCard'
import { useInstitutionReportList } from '~/trader/pages/InstitutionList/hooks/useInstitutionReportList'
import { PATH_INSTITUTION } from '~/trader/routes/routePaths'

export const InstitutionListPage: React.FC = () => {
  const navigate = useNavigate()
  const breadcrumbItems = [{ label: PATH_INSTITUTION.name, bold: true }]

  const { institutionReportList, isLoading } = useInstitutionReportList()

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      {isLoading ? (
        <Loading />
      ) : institutionReportList.length !== 0 ? (
        <Grid
          gridGap={Spacing[4]}
          gridAutoRows="minmax(auto, auto)"
          gridTemplateColumns="repeat(3, 1fr)"
          width="full"
        >
          {institutionReportList.map((report) => (
            <ReportCard
              key={report.id}
              data={report}
              onClick={(): void =>
                navigate(`${PATH_INSTITUTION.path}/${report.id}`)
              }
            />
          ))}
        </Grid>
      ) : (
        <NotFound />
      )}
    </SideMenuLayoutContentLayout>
  )
}
