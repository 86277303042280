import { ThemeOverride, extendTheme } from '@chakra-ui/react'

import { theme as DSTheme } from '@enechain/ecloud-designsystem'

import Checkbox from './checkbox'
import { SemanticColors } from './colors'
import Radio from './radio'
import Switch from './switch'

export const customTheme = {
  light: {
    semanticTokens: {
      colors: SemanticColors,
    },
  },
  dark: {
    semanticTokens: {
      colors: SemanticColors,
    },
  },
  components: { Checkbox, Radio, Switch },
} satisfies ThemeOverride

export const theme = extendTheme(DSTheme, customTheme)
