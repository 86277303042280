import { Timestamp } from '@bufbuild/protobuf'

import { formatYYYYMMdd } from '@enechain/jcex-utils'

type AbstractIndexPrice = {
  referenceDate?: Timestamp
  // GreenIndex_NfcIndexPrice と GreenIndex_JCreditIndexPrice で共通するプロパティは使用しないので、any で流す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export function makeUniqueReferenceDates<T extends AbstractIndexPrice>(
  data: T[],
): string[] {
  return Array.from(
    new Set(
      data
        .map((indexPrice) =>
          indexPrice.referenceDate !== undefined
            ? formatYYYYMMdd(indexPrice.referenceDate.toDate(), '/')
            : '',
        )
        .filter((referenceDate) => referenceDate !== ''),
    ),
  )
}

export function filterIndexPrice<T extends AbstractIndexPrice>(
  data: T[],
  referenceDate: string,
): T[] {
  return data.filter((indexPrice) =>
    indexPrice.referenceDate !== undefined
      ? formatYYYYMMdd(indexPrice.referenceDate.toDate(), '/') === referenceDate
      : false,
  )
}
