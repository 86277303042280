import * as React from 'react'
import { useMemo } from 'react'

import { User } from '@auth0/auth0-react'
import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { RouterProvider, RouterProviderProps } from 'react-router-dom'

import { LoadingJCEXLogo } from '@enechain/ecloud-designsystem'

type ComponentProps = {
  transport: Transport
  queryClient: QueryClient
  user: User | undefined
}

type AppRouterProviderProps = ComponentProps & {
  createRouter: (
    transport: Transport,
    queryClient: QueryClient,
    user?: User,
  ) => RouterProviderProps['router']
} & Omit<RouterProviderProps, 'router'>

const AppRouterProvider: React.FC<AppRouterProviderProps> = ({
  transport,
  queryClient,
  user,
  createRouter,
  fallbackElement = <LoadingJCEXLogo />,
  ...rest
}) => {
  const router = useMemo(() => {
    return createRouter(transport, queryClient, user)
  }, [transport, queryClient, user, createRouter])

  return (
    <RouterProvider
      fallbackElement={fallbackElement}
      router={router}
      {...rest}
    />
  )
}

export default AppRouterProvider
