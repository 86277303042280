import * as React from 'react'
import { useCallback } from 'react'

import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioProps, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  Label,
  RadioButton,
  Spacing,
  Term,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import {
  GeneratorType_Type,
  RenewableEnergyAndTrackingStatus_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import {
  Position_Type,
  Prefecture_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  CardLayout,
  GeneratorForm,
  NumberInputWithComma,
  PrefectureForm,
  SelectBox,
} from '@enechain/jcex-ui'
import { radioStyle } from '@enechain/jcex-ui/src/constants/radio'

import { isOrderActive } from '~/trader/models/orderStatus'
import { useOrderDetail } from '~/trader/pages/NFCOrderDetail/hooks/useOrderDetail'
import { useUpdateOrderOption } from '~/trader/pages/NFCOrderDetail/hooks/useUpdateOrderOption'
import { UpdateOrderSchema } from '~/trader/pages/NFCOrderDetail/schemas/updateOrderSchema'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  formMethods: UseFormReturn<UpdateOrderSchema>
}

export const OrderForm: React.FC<Props> = ({ formMethods }) => {
  const theme = useTheme()
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const { options } = useUpdateOrderOption()

  const watchedPositionType = formMethods.watch('positionType')
  const watchedPrefectureIds = formMethods.watch('prefectureIds')
  const watchedGeneratorIds = formMethods.watch('generatorIds')
  const watchedTrackingStatus = formMethods.watch(
    'renewableEnergyAndTrackingStatusType',
  )

  const setAllPrefecturesAndGenerators = useCallback((): void => {
    formMethods.setValue(
      'prefectureIds',
      options.prefectures
        .map(({ type }) => type)
        .filter((type) => type !== Prefecture_Type.UNSPECIFIED) ?? [],
    )
    formMethods.setValue(
      'generatorIds',
      options.generatorTypes
        .map(({ type }) => type)
        .filter((type) => type !== GeneratorType_Type.UNSPECIFIED) ?? [],
    )
  }, [formMethods, options.prefectures, options.generatorTypes])

  const clearPrefecturesAndGenerators = useCallback((): void => {
    formMethods.setValue('prefectureIds', [])
    formMethods.setValue('generatorIds', [])
  }, [formMethods])

  // 買いの場合は、都道府県と発電所全てを選択し、売りの場合は単一の都道府県と発電所を選択する
  const onChangePositionType = (value: number): void => {
    if (
      value === Position_Type.BID &&
      watchedTrackingStatus ===
        RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING
    ) {
      setAllPrefecturesAndGenerators()
    } else {
      clearPrefecturesAndGenerators()
    }
    formMethods.setValue('positionType', value)
  }

  // 買いの場合は、トラッキングなしの場合は全て選択しておくため、初期値として全て選択させておく
  const onChangeRenewableEnergyAndTracking = (value: number): void => {
    if (
      watchedPositionType === Position_Type.BID &&
      value ===
        RenewableEnergyAndTrackingStatus_Type.RENEWABLE_ENERGY_AND_TRACKING
    ) {
      setAllPrefecturesAndGenerators()
    } else {
      clearPrefecturesAndGenerators()
    }

    formMethods.setValue('renewableEnergyAndTrackingStatusType', value)
  }

  const { data } = useOrderDetail()
  const isActive = isOrderActive(data?.orderInformation?.orderStatus)

  return (
    <FormProvider {...formMethods}>
      <CardLayout title={t('trader:shared.product_information')}>
        <Stack spacing={Spacing[6]}>
          <ColumnContent label={t('domain:shared.product_type')}>
            <Text
              color={theme.semanticTokens.colors.Text.highEmphasis}
              {...Typography.textMd}
            >
              {t('domain:shared.nfc')}
            </Text>
          </ColumnContent>
          <Controller
            control={formMethods.control}
            name="positionType"
            render={({ field: { value } }): React.ReactElement => (
              <Term>
                <Label required>{t('domain:shared.position.title')}</Label>
                <RadioButton
                  isDisabled={!isActive}
                  onChange={(value): void =>
                    onChangePositionType(Number(value))
                  }
                  radios={options.positions.map(
                    ({ type, displayName }) =>
                      ({
                        ...radioStyle,
                        value: type.toString(),
                        label: displayName,
                      } as RadioProps & { label: string }),
                  )}
                  value={value.toString()}
                />
              </Term>
            )}
          />
          <Controller
            control={formMethods.control}
            disabled={!isActive}
            name="deliveryFiscalYear"
            render={({ field: { value } }): React.ReactElement => (
              <Term>
                <Label required>
                  {t('domain:model.order.column.delivery_fiscal_year')}
                </Label>
                <SelectBox<number>
                  maxWidth="25rem"
                  backgroundColor={theme.semanticTokens.colors.Surface.primary}
                  isDisabled={!isActive}
                  isRequired
                  onChange={(value: Maybe<number>): void =>
                    formMethods.setValue('deliveryFiscalYear', Number(value))
                  }
                  selectable={Object.fromEntries(
                    options.deliveryFiscalYears.length > 0
                      ? options.deliveryFiscalYears.map(
                          ({ year, displayName }) => [
                            Number(year),
                            displayName,
                          ],
                        )
                      : [],
                  )}
                  selectedValue={value}
                />
                <Text color={theme.semanticTokens.colors.Text.negativeMid}>
                  {formMethods.formState.errors.deliveryFiscalYear?.message}
                </Text>
              </Term>
            )}
          />
          <Controller
            control={formMethods.control}
            name="renewableEnergyAndTrackingStatusType"
            render={({ field: { value } }): React.ReactElement => (
              <Term>
                <Label required>
                  {t(
                    'domain:model.order.column.nfc.renewable_energy_and_tracking',
                  )}
                </Label>
                <SelectBox<number>
                  maxWidth="25rem"
                  backgroundColor={theme.semanticTokens.colors.Surface.primary}
                  isDisabled={!isActive}
                  isRequired
                  onChange={(value: Maybe<number>): void =>
                    onChangeRenewableEnergyAndTracking(Number(value))
                  }
                  selectable={Object.fromEntries(
                    options.renewableEnergyAndTrackingStatuses.length > 0
                      ? options.renewableEnergyAndTrackingStatuses.map(
                          ({ type, displayName }) => [
                            Number(type),
                            displayName,
                          ],
                        )
                      : [],
                  )}
                  selectedValue={value}
                />
                <Text color={theme.semanticTokens.colors.Text.negativeMid}>
                  {
                    formMethods.formState.errors
                      .renewableEnergyAndTrackingStatusType?.message
                  }
                </Text>
              </Term>
            )}
          />
          <PrefectureForm
            error={formMethods.formState.errors.prefectureIds?.message}
            isDisabled={!isActive}
            onChange={(value): void =>
              formMethods.setValue('prefectureIds', value)
            }
            options={options.prefectures}
            productType={ProductType_Type.NFC}
            selectedPositionType={watchedPositionType}
            selectedTrackingStatus={watchedTrackingStatus}
            values={watchedPrefectureIds}
          />
          <GeneratorForm
            error={formMethods.formState.errors.generatorIds?.message}
            isDisabled={!isActive}
            onChange={(value): void =>
              formMethods.setValue('generatorIds', value)
            }
            options={options.generatorTypes}
            selectedPositionType={watchedPositionType}
            selectedTrackingStatus={watchedTrackingStatus}
            values={watchedGeneratorIds}
          />
          <Controller
            control={formMethods.control}
            disabled={!isActive}
            name="unitPrice"
            render={({ field: { onChange } }): React.ReactElement => (
              <Term>
                <Label required>{t('domain:shared.unit_price.kwh')}</Label>
                <NumberInputWithComma
                  error={formMethods.formState.errors.unitPrice?.message}
                  isDisabled={!isActive}
                  onChange={onChange}
                  value={formMethods.watch('unitPrice')}
                />
              </Term>
            )}
          />
          <Controller
            control={formMethods.control}
            disabled={!isActive}
            name="volume"
            render={({ field: { onChange } }): React.ReactElement => (
              <Term>
                <Label required>{t('domain:shared.volume.kwh')}</Label>
                <NumberInputWithComma
                  error={formMethods.formState.errors.volume?.message}
                  isDisabled={!isActive}
                  onChange={onChange}
                  value={formMethods.watch('volume')}
                />
              </Term>
            )}
          />
        </Stack>
      </CardLayout>
    </FormProvider>
  )
}
