import * as React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'

import { useTheme } from '@enechain/ecloud-designsystem'

export const LoadingWithGray: React.FC = () => {
  const theme = useTheme()

  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      alignItems="center"
      justifyContent="center"
      opacity={0.5}
      backgroundColor={theme.semanticTokens.colors.Surface.neutralMid}
    >
      <Spinner color={theme.semanticTokens.colors.Object.highEmphasis} />
    </Flex>
  )
}
