import { differenceInWeeks, subDays } from 'date-fns'

import {
  InstitutionReportForCustomer,
  NewsletterForCustomer,
} from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'
import {
  formatTimeStampToJSTYYYYMMddDate,
  formatYYYYMMddJp,
} from '@enechain/jcex-utils'

export type CustomerReportModel = {
  id: string
  title: string
  publishedAt: string
  /**
   * @ref:
   * https://www.notion.so/enechain/GX-11a31f8809918008a4faf2f5f107c5fe?pvs=4#12731f88099180de9a0fe03e483585ff
   */
  isNew: boolean
}

export function convertCustomerReportModel(
  data: InstitutionReportForCustomer | NewsletterForCustomer,
): CustomerReportModel {
  const parsedPublishedAt =
    data.publishedAt !== undefined
      ? formatTimeStampToJSTYYYYMMddDate(data.publishedAt)
      : undefined

  return {
    id: data.id,
    title: data.title,
    publishedAt:
      parsedPublishedAt !== undefined
        ? formatYYYYMMddJp(parsedPublishedAt)
        : '',
    isNew:
      parsedPublishedAt !== undefined
        ? // ex.) today: 2024-10-08, publishedAt: 2021-10-01 -> true(7日以内) になるようにする
          differenceInWeeks(subDays(new Date(), 1), parsedPublishedAt) <= 0
        : false,
  }
}

export function convertCustomerReportModelList(
  data: InstitutionReportForCustomer[] | NewsletterForCustomer[],
): CustomerReportModel[] {
  return data.map(convertCustomerReportModel)
}
