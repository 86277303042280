import * as React from 'react'
import { useState } from 'react'

import { Document, Page } from 'react-pdf'

import { FullLoadingView } from '../../common'
import { INITIAL_FILE_URL, PDF_OPTIONS } from './constants'

type Props = {
  pdfUrl: string | undefined
  isLoading: boolean
}

export const ReportPDFView: React.FC<Props> = ({ pdfUrl, isLoading }) => {
  const [pageCount, setPageCount] = useState<number>(1)

  if (pdfUrl === INITIAL_FILE_URL || isLoading) {
    return <FullLoadingView />
  }

  return (
    <Document
      file={pdfUrl}
      options={PDF_OPTIONS}
      loading={<FullLoadingView />}
      onLoadSuccess={({ numPages }: { numPages: number }): void =>
        setPageCount(numPages)
      }
    >
      {Array.from({ length: pageCount }, (_, index) => {
        return (
          <Page key={index} width={960} pageNumber={index + 1} loading={null} />
        )
      })}
    </Document>
  )
}
