import * as React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'

import { PrimitiveColors, useTheme } from '@enechain/ecloud-designsystem'

export const FullLoadingView: React.FC = () => {
  const theme = useTheme()

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100vw"
      height="100vh"
    >
      <Spinner
        color={theme.semanticTokens.colors.Brand.teal}
        emptyColor={PrimitiveColors.gray[200]}
        size="xl"
        speed="0.65s"
        thickness="100px"
      />
    </Flex>
  )
}
