import { useQuery } from '@connectrpc/connect-query'

import { listInstitutionReportsForCustomer } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service-Service_connectquery'

import {
  InstitutionReportModel,
  convertInstitutionReportModelList,
} from '~/trader/pages/InstitutionList/models/InstitutionReport'

type Result = {
  institutionReportList: InstitutionReportModel[]
  isLoading: boolean
}

export function useInstitutionReportList(): Result {
  const { data, isPending } = useQuery(listInstitutionReportsForCustomer)

  const reportModelList = convertInstitutionReportModelList(data)

  return {
    institutionReportList: reportModelList,
    isLoading: isPending,
  }
}
