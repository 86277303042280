import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  SideMenuLayoutAppSwitcherMenu,
  SideMenuLayoutMenuItem,
  SideMenuLayoutPopOverMenuItem,
  SideMenuLayoutUserMenu,
} from '@enechain/ecloud-designsystem'
import {
  EnecAppswitcherRegular,
  FaCircleQuestionRegular,
} from '@enechain/ecloud-icons'

const SUPPORT_PAGE_URL = 'https://www.japan-climate-exchange.com/support'

/**
 * @memo
 * env 参照は apps 側からのみ受け取れるので props で持っているが、複数になった場合は別途対応する
 */
type Props = {
  eCloudUrl: string
}

export const BottomMenu: React.FC<Props> = ({ eCloudUrl }) => {
  const { t } = useTranslation(['common', 'component'])
  const navigate = useNavigate()
  const { logout, user } = useAuth0()

  const onClickSignOut = (): void => {
    void (async (): Promise<void> => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    })()
  }

  return (
    <>
      <SideMenuLayoutMenuItem
        IconLeft={FaCircleQuestionRegular}
        label={t('component:layouts.main_layout.help_center')}
        onClick={(): void => {
          window.location.href = SUPPORT_PAGE_URL
        }}
      />
      <SideMenuLayoutAppSwitcherMenu
        Icon={EnecAppswitcherRegular}
        items={[
          {
            name: 'JCEX Market',
            onClick: (): void => navigate('/'),
          },
          {
            name: 'eCloud',
            onClick: (): void => {
              window.location.href = eCloudUrl
            },
          },
        ]}
        label={t('component:layouts.main_layout.app_switcher')}
      />
      <SideMenuLayoutUserMenu
        popOverMenu={
          <>
            <SideMenuLayoutPopOverMenuItem
              label={t('common:label.profile')}
              onClick={(): void => {
                window.location.href = eCloudUrl + '/profile'
              }}
            />
            <SideMenuLayoutPopOverMenuItem
              label={t('common:label.sign_out')}
              onClick={onClickSignOut}
            />
          </>
        }
        userIconSrc={user?.picture ?? ''}
        userName={user?.name ?? ''}
      />
    </>
  )
}
