import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Image, Stack, Text } from '@chakra-ui/react'

import {
  Divider,
  Spacing,
  Tooltip,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { Card } from '@enechain/jcex-ui'

import { CustomerReportModel } from '~/trader/models/customerReport'
import { InstitutionReportModel } from '~/trader/pages/InstitutionList/models/InstitutionReport'

type Props = {
  data: InstitutionReportModel | CustomerReportModel
  onClick: () => void
}

const MAX_TITLE_LENGTH = 75

const isInstitutionReport = (
  data: InstitutionReportModel | CustomerReportModel,
): data is InstitutionReportModel => 'meetingDate' in data

export const ReportCard: React.FC<Props> = ({ data, onClick }) => {
  const theme = useTheme()
  const { t } = useTranslation('domain')

  return (
    <Card
      onClick={onClick}
      position="relative"
      overflow="hidden"
      cursor="pointer"
      _hover={{
        backgroundColor: theme.semanticTokens.colors.Hover.lowEmphasis,
      }}
    >
      {data.isNew && (
        <Box position="absolute" top="0" right="0" width="44px">
          <Image src="/new_flag.png" alt="New Flag" />
        </Box>
      )}
      <Stack gap={Spacing[4]}>
        <Tooltip
          isHidden={data.title.length < MAX_TITLE_LENGTH}
          label={data.title}
          hasArrow
        >
          <Text
            height="74px"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '3',
            }}
            {...Typography.headerMd}
          >
            {data.title}
          </Text>
        </Tooltip>
        <Divider />
        <Stack
          gap={Spacing[2]}
          color={theme.semanticTokens.colors.Text.midEmphasis}
        >
          <Text>
            {t('model.institution_report.column.published_at_with_value', {
              value: data.publishedAt,
            })}
          </Text>
          {isInstitutionReport(data) && (
            <Text>
              {t('model.institution_report.column.meeting_date_with_value', {
                value: data.meetingDate,
              })}
            </Text>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
