import * as React from 'react'

import { Tooltip } from 'recharts'

import { Box, Flex, Stack } from '@chakra-ui/react'

import {
  BorderRadius,
  Spacing,
  UseThemeReturn,
} from '@enechain/ecloud-designsystem'
import { TAB_TYPES, TabKey } from '@enechain/jcex-ui'
import { stringUtils } from '@enechain/jcex-utils'

import { NFC_DECIMAL_POINT } from '~/trader/pages/CarbonIndex/constants/decimalPoint'

type Props = {
  theme: UseThemeReturn
  tab: TabKey
}

/**
 * @memo
 * recharts の render timing の関係で renderChartTooltip として export している
 */
export const renderChartTooltip: React.FC<Props> = ({ theme, tab }) => {
  return (
    <Tooltip
      content={({ payload }): React.ReactElement => {
        let date = ''
        if (
          payload !== undefined &&
          payload.length > 0 &&
          payload[0].payload !== undefined
        ) {
          const payloadItem = payload[0].payload as { referenceDate: string }
          date = payloadItem.referenceDate
        }
        return (
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor={theme.semanticTokens.colors.Border.midEmphasis}
            borderRadius={BorderRadius.radiiMd}
            backgroundColor={theme.semanticTokens.colors.Surface.primary}
            marginX={Spacing[4]}
          >
            {stringUtils.isNotEmpty(date) && (
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor={theme.semanticTokens.colors.Surface.secondary}
                paddingY={Spacing[1]}
              >
                {date}
              </Flex>
            )}
            <Stack padding={Spacing[2]} spacing={Spacing[2]}>
              {payload !== undefined &&
                payload.map((item, index) => (
                  <Flex
                    key={`item-${index}`}
                    alignItems="center"
                    gap={Spacing[1]}
                  >
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor={item.color}
                    />
                    {item.name}:
                    {tab === TAB_TYPES.NFC
                      ? item.value?.toLocaleString('en-US', {
                          minimumFractionDigits: NFC_DECIMAL_POINT,
                          maximumFractionDigits: NFC_DECIMAL_POINT,
                        }) ?? '-'
                      : item.value}
                  </Flex>
                ))}
            </Stack>
          </Box>
        )
      }}
    />
  )
}
