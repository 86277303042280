import { useCallback } from 'react'

import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

import { Maybe } from '~/trader/types/AdvancedTypes'

type ReportState = {
  id: string
  url: string
}

type MutationReturnType = {
  setReportState: (value: ReportState) => void
}

const institutionReportState = atom<ReportState[]>({
  key: 'reportState',
  default: [],
})

export function useReportQueries(): Maybe<ReportState[]> {
  return useRecoilValue(institutionReportState)
}

export function useReportMutations(): MutationReturnType {
  const setState = useSetRecoilState(institutionReportState)
  const update = useCallback(
    (value: ReportState) => {
      setState((prev) =>
        prev.some((item) => item.id === value.id) ? prev : [...prev, value],
      )
    },
    [setState],
  )

  return {
    setReportState: update,
  }
}
