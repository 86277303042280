import { Product } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { GreenIndex_Summary } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'
import { formatYYYYMMdd } from '@enechain/jcex-utils'

import { Maybe } from '~/trader/types/AdvancedTypes'

type IndexPriceModel = {
  price: string
  changeAmount: string
  changeRate: number
  isNull: boolean
  productType: Product
}

export type JCreditGreenIndexSummaryModel = {
  averagePrices: Array<Pick<IndexPriceModel, 'price' | 'productType'>>
  latestPrices: IndexPriceModel[]
  latestReferenceDate: string
}

export function convertJCreditGreenIndexSummaryModel(
  greenIndexSummary: Maybe<GreenIndex_Summary>,
  allProducts: Product[],
): Maybe<JCreditGreenIndexSummaryModel> {
  if (greenIndexSummary === undefined) {
    return undefined
  }

  const averagePrices = allProducts.map((product) => {
    const targetPrice = greenIndexSummary.jcreditAveragePrices.find(
      (averagePrice) => averagePrice.product?.id === product.id,
    )?.price
    return {
      price:
        targetPrice !== undefined ? Number(targetPrice).toLocaleString() : '-',
      productType: product,
    }
  })

  const latestPrices = allProducts.map((product) => {
    const targetPrice = greenIndexSummary.latestJcreditIndexPrices.find(
      (indexPrice) => indexPrice.product?.id === product.id,
    )
    return {
      price:
        targetPrice?.indexValue?.price !== undefined
          ? Number(targetPrice.indexValue.price).toLocaleString()
          : '-',
      changeAmount:
        targetPrice?.indexValue?.changeAmount !== undefined
          ? Number(targetPrice.indexValue.changeAmount).toLocaleString()
          : '0',
      changeRate: targetPrice?.indexValue?.changeRate ?? 0,
      isNull: targetPrice === undefined,
      productType: product,
    }
  })
  return {
    averagePrices,
    latestPrices,
    latestReferenceDate:
      greenIndexSummary.latestReferenceDate !== undefined
        ? formatYYYYMMdd(greenIndexSummary.latestReferenceDate.toDate(), '/')
        : '',
  }
}
