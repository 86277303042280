import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import {
  isIgnoreApplicationMessage,
  isIgnoreAuth0Message,
} from '@enechain/jcex-libs'

if (
  import.meta.env.VITE_DD_APPLICATION_ID != null &&
  import.meta.env.VITE_DD_CLIENT_TOKEN != null &&
  import.meta.env.VITE_DD_ENV != null &&
  import.meta.env.VITE_DD_ENV !== 'local'
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'jcex-frontend-trader',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    env: import.meta.env.VITE_DD_ENV,
    defaultPrivacyLevel: [
      'jcex-frontend-trader-dev',
      'jcex-frontend-trader-stg',
    ].includes(import.meta.env.VITE_DD_ENV)
      ? 'allow'
      : 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.enechain\.com/],
    beforeSend: (event) => {
      const errorMessage =
        event.type === 'error' ? event.error.message : undefined
      if (isIgnoreAuth0Message(errorMessage)) {
        return false
      }
      if (isIgnoreApplicationMessage(errorMessage)) {
        return false
      }
      return true
    },
  })
  datadogRum.startSessionReplayRecording()

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'jcex-frontend-trader',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    sessionSampleRate: 100,
    env: import.meta.env.VITE_DD_ENV,
    beforeSend: (log) => {
      const errorMessage =
        log.error != null ? String(log.error.stack) : undefined
      return !(
        isIgnoreAuth0Message(log.message) ||
        isIgnoreAuth0Message(errorMessage) ||
        isIgnoreApplicationMessage(log.message) ||
        isIgnoreApplicationMessage(errorMessage)
      )
    },
  })
}
