import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  Spacing,
  TertiaryButton,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { FaSquareListRegular } from '@enechain/ecloud-icons'

type Props = {
  message: string
}

export const PermissionDeniedErrorPage: React.FC<Props> = ({ message }) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const { logout } = useAuth0()

  const onClickLogin = async (): Promise<void> => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={Spacing[2]}
      width="100%"
      height="100vh"
    >
      <FaSquareListRegular
        width="64px"
        height="64px"
        color={theme.semanticTokens.colors.Object.lowEmphasis}
      />
      <Text
        textColor={theme.semanticTokens.colors.Text.midEmphasis}
        {...Typography.headerMd}
      >
        {message}
      </Text>
      <TertiaryButton onClick={onClickLogin}>
        {t('label.sign_out')}
      </TertiaryButton>
    </Flex>
  )
}
