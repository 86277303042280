import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, RadioProps, Stack } from '@chakra-ui/react'

import {
  ColumnContent,
  NewDateRangePicker,
  RadioButton,
  Select,
  Spacing,
  Tooltip,
  calendarType,
} from '@enechain/ecloud-designsystem'
import {
  FilterCheckboxGroup,
  FilterContent,
  TAB_TYPES,
  TabKey,
} from '@enechain/jcex-ui'
import { radioStyle } from '@enechain/jcex-ui/src/constants/radio'

import {
  PRODUCT_TYPE_MAP,
  calcNFCProductTypes,
  getDateFromPresetDateRange,
} from '~/trader/pages/CarbonIndex/components/FilterInputs/helper'
import {
  FILTER_DATE_RANGE_OPTION,
  TRACKABLE_YEAR,
} from '~/trader/pages/CarbonIndex/constants/filter'
import { FilterDateRange } from '~/trader/pages/CarbonIndex/types/filterDateRange'
import {
  GreenIndexFilterOptions,
  GreenIndexFilterState,
} from '~/trader/pages/CarbonIndex/types/filterGreenIndex'

type Props = {
  filters: GreenIndexFilterState
  options: GreenIndexFilterOptions
  tab: TabKey
}

export type CalendarType = (typeof calendarType)[keyof typeof calendarType]

/**
 * @memo
 * 年度を全て選択するときの value
 */
const ALL_YEAR = 'all'

export const GreenIndexFilterInputs: React.FC<Props> = ({
  filters,
  options,
  tab,
}) => {
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const [selectedCalendarType, setSelectedCalendarType] =
    React.useState<CalendarType>(calendarType.DAY)
  const [filterDatePreset, setFilterDatePreset] =
    React.useState<FilterDateRange>(FILTER_DATE_RANGE_OPTION.YEAR)
  const [selectedRenewableEnergyTypes, setSelectedRenewableEnergyTypes] =
    React.useState<number[]>([
      PRODUCT_TYPE_MAP.REUSABLE_ENERGY_YES,
      PRODUCT_TYPE_MAP.REUSABLE_ENERGY_NO,
    ])
  const [selectedTrackingTypes, setSelectedTrackingTypes] = React.useState<
    number[]
  >([PRODUCT_TYPE_MAP.TRACKING_YES, PRODUCT_TYPE_MAP.TRACKING_NO])

  const { state, setState, setDateState, removeTrackingYear } = filters

  const convertYearOptions = options.nfc.deliveryFiscalYear.map((item) => ({
    label: item.displayName,
    value: item.year.toString(),
  }))
  const yearOptions = options.nfc.deliveryFiscalYear.every((item) =>
    filters.state.nfcDeliveryFiscalYears.includes(item.year),
  )
    ? convertYearOptions
    : [{ label: t('common:label.all'), value: ALL_YEAR }, ...convertYearOptions]

  const handleChangeDate = (value: FilterDateRange): void => {
    setFilterDatePreset(value)

    if (value === FILTER_DATE_RANGE_OPTION.CUSTOM) {
      return
    }

    setDateState(getDateFromPresetDateRange(value), new Date())
  }

  return (
    <FilterContent>
      <ColumnContent label={t('trader:page.carbon_index.display_period')}>
        <Flex gap={Spacing[4]}>
          <RadioButton
            wrap="nowrap"
            onChange={(value: FilterDateRange): void => handleChangeDate(value)}
            radios={Object.entries(FILTER_DATE_RANGE_OPTION).map(
              ([_, value]) =>
                ({
                  ...radioStyle,
                  value: value.toString(),
                  label: t(`common:date.${value}`),
                } as RadioProps as { label: string }),
            )}
            value={filterDatePreset.toString()}
          />
          <Tooltip
            isHidden={filterDatePreset === FILTER_DATE_RANGE_OPTION.CUSTOM}
            label={t('trader:page.carbon_index.disable_date_range_picker')}
          >
            <Stack width="15rem">
              <NewDateRangePicker
                from={state.fromDate}
                isDisabled={
                  filterDatePreset !== FILTER_DATE_RANGE_OPTION.CUSTOM
                }
                onChange={setDateState}
                onChangeCalendarType={setSelectedCalendarType}
                segmentedControlCalendarType={[
                  calendarType.DAY,
                  calendarType.MONTH,
                  calendarType.YEAR,
                ]}
                selectedCalendarType={selectedCalendarType}
                to={state.toDate}
              />
            </Stack>
          </Tooltip>
        </Flex>
      </ColumnContent>
      {tab === TAB_TYPES.JCREDIT && (
        <ColumnContent label={t('domain:model.carbon_index.column.product')}>
          <FilterCheckboxGroup<number>
            options={Object.fromEntries(
              options.jCredit.map(({ id, name }) => [name, id]),
            )}
            selectedItem={state.jCreditProductTypes}
            setSelectedItem={(value: number[]): void =>
              setState({
                key: 'jCreditProductTypes',
                value,
              })
            }
          />
        </ColumnContent>
      )}
      {tab === TAB_TYPES.NFC && (
        <Flex gap={Spacing[6]}>
          <ColumnContent
            flexSize={0}
            label={t('domain:model.order.column.nfc.renewable_energy')}
          >
            <FilterCheckboxGroup
              options={Object.fromEntries([
                [
                  t('domain:model.order.column.nfc.yes'),
                  PRODUCT_TYPE_MAP.REUSABLE_ENERGY_YES,
                ],
                [
                  t('domain:model.order.column.nfc.no'),
                  PRODUCT_TYPE_MAP.REUSABLE_ENERGY_NO,
                ],
              ])}
              selectedItem={selectedRenewableEnergyTypes}
              setSelectedItem={(value): void => {
                setState({
                  key: 'nfcProductTypes',
                  value: calcNFCProductTypes([
                    ...value,
                    ...selectedTrackingTypes,
                  ]),
                })
                setSelectedRenewableEnergyTypes(value)
              }}
            />
          </ColumnContent>
          <ColumnContent
            flexSize={0}
            label={t('domain:model.order.column.nfc.tracking')}
          >
            <FilterCheckboxGroup
              isDisabled={state.nfcDeliveryFiscalYears.every(
                (deliveryFiscalYear) => deliveryFiscalYear > TRACKABLE_YEAR,
              )}
              options={Object.fromEntries([
                [
                  t('domain:model.order.column.nfc.yes'),
                  PRODUCT_TYPE_MAP.TRACKING_YES,
                ],
                [
                  t('domain:model.order.column.nfc.no'),
                  PRODUCT_TYPE_MAP.TRACKING_NO,
                ],
              ])}
              selectedItem={selectedTrackingTypes}
              setSelectedItem={(value): void => {
                setState({
                  key: 'nfcProductTypes',
                  value: calcNFCProductTypes([
                    ...value,
                    ...selectedRenewableEnergyTypes,
                  ]),
                })
                setSelectedTrackingTypes(value)
              }}
            />
          </ColumnContent>
          <Box flexGrow={1} maxWidth="50rem">
            <ColumnContent
              label={t('domain:model.carbon_index.column.creation_year')}
            >
              <Select<{ label: string; value: string; isFixed?: boolean }, true>
                isMulti
                onChange={(value): void => {
                  if (value.some((item) => item.value === ALL_YEAR)) {
                    setState({
                      key: 'nfcDeliveryFiscalYears',
                      value: options.nfc.deliveryFiscalYear.map(
                        (item) => item.year,
                      ),
                    })
                    return
                  }

                  const changeYears = value.map((item) => Number(item.value))

                  // 2023年度が選択されていない場合、トラッキングのフィルターを元に戻す
                  if (
                    !value.some(
                      (item) => item.value === TRACKABLE_YEAR.toString(),
                    )
                  ) {
                    setSelectedTrackingTypes([
                      PRODUCT_TYPE_MAP.TRACKING_YES,
                      PRODUCT_TYPE_MAP.TRACKING_NO,
                    ])
                    removeTrackingYear({
                      nfcDeliveryFiscalYears: changeYears,
                      nfcProductTypes: calcNFCProductTypes([
                        ...selectedRenewableEnergyTypes,
                        PRODUCT_TYPE_MAP.TRACKING_YES,
                        PRODUCT_TYPE_MAP.TRACKING_NO,
                      ]),
                    })
                    return
                  }

                  setState({
                    key: 'nfcDeliveryFiscalYears',
                    value: changeYears,
                  })
                }}
                options={yearOptions}
                selectedOptions={options.nfc.deliveryFiscalYear
                  .filter((option) =>
                    state.nfcDeliveryFiscalYears.includes(option.year),
                  )
                  .map((item) => ({
                    label: item.displayName,
                    value: item.year.toString(),
                  }))}
              />
            </ColumnContent>
          </Box>
        </Flex>
      )}
    </FilterContent>
  )
}
