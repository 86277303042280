import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'

import { Spacing, useTheme } from '@enechain/ecloud-designsystem'

type Props = {
  selectedValue: string
  selectable: Record<string, string>
  isDisabled?: boolean
  onChange: (value: string) => void
}
export const FilterRadioGroup = ({
  onChange,
  selectedValue,
  selectable,
  isDisabled = false,
}: Props): React.ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation('common')

  return (
    <RadioGroup
      alignItems="center"
      height="full"
      isDisabled={isDisabled}
      onChange={(value): void => onChange(value)}
      value={selectedValue}
    >
      <Flex gap={Spacing[2]} height="full">
        <Radio key="" value="">
          <Text color={theme.semanticTokens.colors.Text.highEmphasis}>
            {t('label.unspecified')}
          </Text>
        </Radio>
        {Object.entries(selectable).map(([key, value]) => (
          <Radio key={key} value={key}>
            <Text color={theme.semanticTokens.colors.Text.highEmphasis}>
              {value}
            </Text>
          </Radio>
        ))}
      </Flex>
    </RadioGroup>
  )
}
