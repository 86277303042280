import * as React from 'react'

import { Stack } from '@chakra-ui/react'

import {
  FlexTableCell,
  FlexTableRow,
  useTheme,
} from '@enechain/ecloud-designsystem'
import {
  PositionIcon,
  RenewableEnergyAndTrackingBadge,
} from '@enechain/jcex-ui'

import { NonFITDealHistoryListColumnKey } from '~/trader/pages/HistoryList/components/NonFITNFC/DealListTable/columnStyles'
import { DealtOrderModelForNFCOrderHistory } from '~/trader/pages/HistoryList/models/NonFITNFC/dealHistory'

type Props = {
  data: DealtOrderModelForNFCOrderHistory
}

const NonFITOrderDealListTableBodyRow: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  return (
    <Stack
      _hover={{
        backgroundColor: theme.semanticTokens.colors.Hover.lowEmphasis,
      }}
    >
      <FlexTableRow>
        <FlexTableCell<NonFITDealHistoryListColumnKey> columnKey="position">
          <PositionIcon type={data.position.type} />
        </FlexTableCell>
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="deliveryFiscalYear"
          contents={{
            text: data.deliveryFiscalYear,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey> columnKey="renewableEnergyAndTrackingStatus">
          <RenewableEnergyAndTrackingBadge
            type={data.renewableEnergyAndTrackingStatus}
          />
        </FlexTableCell>
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="prefecture"
          contents={{
            text: data.displayPrefectureName,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="generatorType"
          contents={{
            text: data.displayGeneratorTypeName,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="unitPrice"
          contents={{
            text: data.volumeAndUnitPrice.unitPrice,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="volume"
          contents={{
            text: data.volumeAndUnitPrice.volume,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="counterpartyOrganizationName"
          contents={{
            text: data.counterpartyOrganizationName,
          }}
        />
        <FlexTableCell<NonFITDealHistoryListColumnKey>
          columnKey="dealtAt"
          contents={{
            text: data.dealAt,
          }}
        />
      </FlexTableRow>
    </Stack>
  )
}

export default React.memo(NonFITOrderDealListTableBodyRow)
