import * as React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { Stack } from '@chakra-ui/react'

import {
  FlexTableCell,
  FlexTableRow,
  Link,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { PositionIcon, StatusBadge } from '@enechain/jcex-ui'

import { JCreditOrderHistoryListColumnKey } from '~/trader/pages/HistoryList/components/JCredit/OrderListTable/columnStyles'
import { OrderModelForJCreditOrderHistory } from '~/trader/pages/HistoryList/models/JCredit/orderHistory'
import { PATH_HISTORY_JCREDIT_ORDER } from '~/trader/routes/routePaths'

type Props = {
  data: OrderModelForJCreditOrderHistory
}

const JCreditOrderHistoryListTableBodyRow: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  return (
    <Stack
      _hover={{
        backgroundColor: theme.semanticTokens.colors.Hover.lowEmphasis,
      }}
    >
      <Link
        as={RouterLink}
        _hover={{ textDecoration: 'none' }}
        to={`${PATH_HISTORY_JCREDIT_ORDER.path}/${data.id}`}
      >
        <FlexTableRow>
          <FlexTableCell<JCreditOrderHistoryListColumnKey> columnKey="position">
            <PositionIcon type={data.position.type} />
          </FlexTableCell>
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="deliveryFiscalYear"
            contents={{
              text: data.deliveryFiscalYear,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="productName"
            contents={{
              text: data.productName,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="prefecture"
            contents={{
              text: data.displayPrefecturesName,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="assignedTrader"
            contents={{
              text: data.assignedTraderName,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="unitPrice"
            contents={{
              text: data.unitPrice,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="volume"
            contents={{
              text: data.volume,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey>
            columnKey="createdAt"
            contents={{
              text: data.createdAt,
            }}
          />
          <FlexTableCell<JCreditOrderHistoryListColumnKey> columnKey="orderStatus">
            <StatusBadge value={data.orderStatus} />
          </FlexTableCell>
        </FlexTableRow>
      </Link>
    </Stack>
  )
}

export default React.memo(JCreditOrderHistoryListTableBodyRow)
