import * as React from 'react'

import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'

import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react'

import { findFeature } from '@enechain/jcex-libs'
import { DARK_MODE } from '@enechain/jcex-libs/src/FeatureFlag/flags'

import { theme } from '../constants'
import GoogleTagProvider from './GoogleTagProvider'

type Props = {
  gtmId: string | undefined
  children: React.ReactNode
}

const LibProvider: React.FC<Props> = ({ gtmId, children }: Props) => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider
        value={findFeature(DARK_MODE)?.active === true ? 'dark' : 'light'}
      >
        <RecoilRoot>
          <HelmetProvider>
            <GoogleTagProvider gtmId={gtmId}>{children}</GoogleTagProvider>
          </HelmetProvider>
        </RecoilRoot>
      </ColorModeProvider>
    </ChakraProvider>
  )
}

export default LibProvider
