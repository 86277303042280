import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { Position_Type } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

type Props = {
  type: Position_Type
}

// memo(shunya): DesignSystem に置く想定、一時的にここに置く
const PositionIcon: React.FC<Props> = ({ type }) => {
  const theme = useTheme()
  const { t } = useTranslation('domain')

  const displayTradeType = React.useMemo(() => {
    switch (type) {
      case Position_Type.BID:
        return {
          label: t('shared.position.bid_short'),
          backgroundColor: theme.semanticTokens.colors.Brand.teal,
        }
      case Position_Type.OFFER:
        return {
          label: t('shared.position.offer_short'),
          backgroundColor: theme.semanticTokens.colors.Brand.pink,
        }
      default:
        return {
          label: '',
          backgroundColor: '',
        }
    }
  }, [type, t, theme])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="20px"
      height="20px"
      color={theme.semanticTokens.colors.Text.highEmphasisInverse}
      borderRadius={BorderRadius.radiiSm}
      backgroundColor={displayTradeType.backgroundColor}
    >
      <Text {...Typography.headerSm}>{displayTradeType.label}</Text>
    </Flex>
  )
}

export default React.memo(PositionIcon)
