import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, Text } from '@chakra-ui/react'

import {
  Link,
  Spacing,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { FaCircleQuestionRegular } from '@enechain/ecloud-icons'

export const NotFound: React.FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={Spacing[2]}
      width="100%"
      height="100%"
    >
      <FaCircleQuestionRegular
        width="64px"
        height="64px"
        color={theme.semanticTokens.colors.Object.lowEmphasis}
      />
      <Text {...Typography.headerLg}>{t('error.page_not_found')}</Text>
      <Link
        as="button"
        color={theme.semanticTokens.colors.Text.link}
        onClick={(): void => navigate('/')}
      >
        {t('action.negative.back_home')}
      </Link>
    </Flex>
  )
}
