import { ListInstitutionReportsForCustomerResponse } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service_pb'
import {
  formatTimeStampToJSTYYYYMMddDate,
  formatYYYYMMddJp,
} from '@enechain/jcex-utils'

import {
  CustomerReportModel,
  convertCustomerReportModel,
} from '~/trader/models/customerReport'
import { Maybe } from '~/trader/types/AdvancedTypes'

export type InstitutionReportModel = CustomerReportModel & {
  meetingDate: string
}

export function convertInstitutionReportModelList(
  data: Maybe<ListInstitutionReportsForCustomerResponse>,
): InstitutionReportModel[] {
  if (data === undefined) {
    return []
  }

  return data.institutionReports.map((report) => {
    return {
      ...convertCustomerReportModel(report),
      meetingDate:
        report.meetingDate !== undefined
          ? formatYYYYMMddJp(
              formatTimeStampToJSTYYYYMMddDate(report.meetingDate),
            )
          : '',
    }
  })
}
