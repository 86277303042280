import * as React from 'react'
import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Text } from '@chakra-ui/react'

import { ErrorText, Input, useTheme } from '@enechain/ecloud-designsystem'
import { stringUtils } from '@enechain/jcex-utils'

type Props = {
  value: string | undefined
  onChange: (value: string | undefined) => void
  description?: string
  error?: string
  isDisabled?: boolean
}

const MAX_INPUT_LENGTH = 15

export const NumberInputWithComma: React.FC<Props> = ({
  value,
  onChange,
  description,
  error,
  isDisabled,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const [displayValue, setDisplayValue] = React.useState<string>('')

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(
        value !== undefined && value.length > 0 && !isNaN(Number(value))
          ? Number(value).toLocaleString('en-US', {
              maximumFractionDigits: MAX_INPUT_LENGTH,
            })
          : '',
      )
    }
  }, [value, displayValue])

  return (
    <>
      <Input
        width="25rem"
        isDisabled={isDisabled}
        maxLength={MAX_INPUT_LENGTH}
        onBlur={(): void => {
          setDisplayValue(
            displayValue.length > 0 && !isNaN(Number(displayValue))
              ? Number(displayValue).toLocaleString('en-US', {
                  maximumFractionDigits: MAX_INPUT_LENGTH,
                })
              : '',
          )
        }}
        onChange={(event): void => {
          const changeInput = event.target.value.replace(/,/g, '')
          setDisplayValue(changeInput)
          onChange(
            stringUtils.isNotEmpty(changeInput) && !isNaN(Number(changeInput))
              ? changeInput
              : undefined,
          )
        }}
        onFocus={(): void => {
          setDisplayValue(displayValue.replaceAll(',', ''))
        }}
        placeholder={t('form.placeholder.short_half_size_number')}
        value={displayValue}
      />
      {description !== undefined && (
        <Text color={theme.semanticTokens.colors.Text.midEmphasis}>
          {description}
        </Text>
      )}
      {error !== undefined && <ErrorText>{error}</ErrorText>}
    </>
  )
}
