import { useQuery } from '@connectrpc/connect-query'

import { listNewslettersForCustomer } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service-Service_connectquery'

import {
  NewsletterModel,
  convertNewsletterModelList,
} from '~/trader/pages/NewsletterList/models/Newsletter'

type Result = {
  newsletterList: NewsletterModel[]
  isLoading: boolean
}

export function useNewsletterList(): Result {
  const { data, isPending } = useQuery(listNewslettersForCustomer)

  const reportModelList = convertNewsletterModelList(data)

  return {
    newsletterList: reportModelList,
    isLoading: isPending,
  }
}
