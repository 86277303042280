import * as React from 'react'
import { Suspense } from 'react'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { useNavigate, useParams } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
} from '@enechain/ecloud-designsystem'
import {
  FixedContentLayout,
  FullLoadingView,
  NotFound,
  ReportPDFView,
} from '@enechain/jcex-ui'

import { useInstitutionReport } from '~/trader/pages/InstitutionDetail/hooks/useInstitutionReport'
import {
  PATH_INSTITUTION,
  PATH_INSTITUTION_DETAIL,
} from '~/trader/routes/routePaths'

export const InstitutionDetailPage: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { pdfUrl, isLoading } = useInstitutionReport({ id })

  const breadcrumbItems = [
    {
      label: PATH_INSTITUTION.name,
      onClick: (): void => navigate(PATH_INSTITUTION.path),
    },
    { label: PATH_INSTITUTION_DETAIL.name, bold: true },
  ]

  return (
    <SideMenuLayoutContentLayout
      header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
    >
      <FixedContentLayout>
        <Flex alignItems="center" flexDirection="column" width="full">
          <Suspense fallback={<FullLoadingView />}>
            {id !== undefined ? (
              <ReportPDFView pdfUrl={pdfUrl} isLoading={isLoading} />
            ) : (
              <NotFound />
            )}
          </Suspense>
        </Flex>
      </FixedContentLayout>
    </SideMenuLayoutContentLayout>
  )
}
