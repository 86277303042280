import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Typography, useTheme } from '@enechain/ecloud-designsystem'
import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'

import { useVolumeUnitQueries } from '../../../hooks'

type Props = {
  product: ProductType_Type
  position: Position_Type
  headerType: 'volume' | 'unitPrice'
}

const Volume = ({
  isBid,
  volume,
}: {
  isBid: boolean
  volume: 'kwh' | 'hundred_million_kwh' | 'mw' | 't_co2'
}): React.ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation('common')

  return (
    <Flex
      flexDirection="column"
      overflow="hidden"
      color={
        isBid
          ? theme.semanticTokens.colors.Text.bid
          : theme.semanticTokens.colors.Text.offer
      }
    >
      <Box height="18px" />
      <Text {...Typography.textLabel}>{t(`unit.${volume}`)}</Text>
    </Flex>
  )
}

const UnitPrice = ({
  isBid,
  unitPrice,
}: {
  isBid: boolean
  unitPrice: 'yen_per_kwh' | 'yen_per_t_co2'
}): React.ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation(['common', 'domain'])

  return (
    <Flex
      flexDirection="column"
      overflow="hidden"
      color={
        isBid
          ? theme.semanticTokens.colors.Text.bid
          : theme.semanticTokens.colors.Text.offer
      }
    >
      <Text {...Typography.textLabel}>
        {t(`domain:shared.position.${isBid ? 'bid' : 'offer'}`)}
      </Text>
      <Text {...Typography.textLabel}>{t(`common:unit.${unitPrice}`)}</Text>
    </Flex>
  )
}

const VolumeAndUnitPriceTableHeader: React.FC<Props> = ({
  product,
  position,
  headerType,
}) => {
  const selectedUnit = useVolumeUnitQueries()

  const isBid = position === Position_Type.BID
  const getVolumeText = (): 'kwh' | 'hundred_million_kwh' | 'mw' | 't_co2' => {
    switch (product) {
      case ProductType_Type.NFC:
        return selectedUnit
      case ProductType_Type.J_CREDIT:
        return 't_co2'
      default:
        return 'kwh'
    }
  }

  const getUnitPriceText = (): 'yen_per_kwh' | 'yen_per_t_co2' => {
    switch (product) {
      case ProductType_Type.NFC:
        return 'yen_per_kwh'
      case ProductType_Type.J_CREDIT:
        return 'yen_per_t_co2'
      default:
        return 'yen_per_kwh'
    }
  }

  switch (headerType) {
    case 'volume':
      return <Volume isBid={isBid} volume={getVolumeText()} />
    case 'unitPrice':
      return <UnitPrice isBid={isBid} unitPrice={getUnitPriceText()} />
    default:
      return null
  }
}

export default React.memo(VolumeAndUnitPriceTableHeader)
