import * as React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, Text, useDisclosure } from '@chakra-ui/react'

import {
  DangerButton,
  DangerModal,
  PrimaryButton,
  PrimaryModal,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
  TertiaryButton,
} from '@enechain/ecloud-designsystem'
import {
  FixedContentLayout,
  FormErrorAlert,
  NotFound,
  useScrollRef,
} from '@enechain/jcex-ui'

import { isOrderActive } from '~/trader/models/orderStatus'
import { OrderForm } from '~/trader/pages/NFCOrderDetail/components/OrderForm'
import OrderInformation from '~/trader/pages/NFCOrderDetail/components/OrderInformation'
import { SAFE_ORDER_FORM_VALUE } from '~/trader/pages/NFCOrderDetail/constants/form'
import { useCancelOrder } from '~/trader/pages/NFCOrderDetail/hooks/useCancelOrder'
import { useOrderDetail } from '~/trader/pages/NFCOrderDetail/hooks/useOrderDetail'
import { useUpdateOrder } from '~/trader/pages/NFCOrderDetail/hooks/useUpdateOrder'
import {
  UpdateOrderSchema,
  updateOrderSchema,
} from '~/trader/pages/NFCOrderDetail/schemas/updateOrderSchema'
import {
  PATH_HISTORY,
  PATH_HISTORY_NFC_ORDER_DETAIL,
} from '~/trader/routes/routePaths'

const OrderDetailPage: React.FC = () => {
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const navigate = useNavigate()
  const cancelDisclosure = useDisclosure()
  const updateDisclosure = useDisclosure()

  const { scrollElementRef, handleScroll } = useScrollRef()

  const { data } = useOrderDetail()
  const { invokeUpdateOrder, isLoading: isLoadingUpdate } = useUpdateOrder()
  const { invokeCancelOrder, isLoading: isLoadingCancel } = useCancelOrder()

  const formMethods = useForm<UpdateOrderSchema>({
    resolver: zodResolver(updateOrderSchema),
    defaultValues: SAFE_ORDER_FORM_VALUE,
    values: data?.formValue,
  })

  const breadcrumbItems = [
    {
      label: PATH_HISTORY.name,
      onClick: (): void => navigate(PATH_HISTORY.path),
    },
    { label: PATH_HISTORY_NFC_ORDER_DETAIL.name, bold: true },
  ]

  const handleError = (): void => {
    handleScroll()
  }

  const onSubmit = async (value: UpdateOrderSchema): Promise<void> => {
    const response = await invokeUpdateOrder(value)
    if (response.isSuccess) {
      navigate(PATH_HISTORY.path)
    }
  }

  const onClickCancel = async (): Promise<void> => {
    const response = await invokeCancelOrder()
    if (response.isSuccess) {
      navigate(PATH_HISTORY.path)
    }
  }

  if (data === undefined) {
    return <NotFound />
  }

  const isActive = isOrderActive(data?.orderInformation?.orderStatus)

  return (
    <>
      <DangerModal
        actionText={t('common:action.negative.revocation')}
        bodyChildren={
          <Text>
            {t('common:description.cancel', {
              value: t('domain:model.order.title'),
            })}
          </Text>
        }
        closeText={t('common:action.negative.cancel')}
        disclosure={cancelDisclosure}
        isLoading={isLoadingCancel}
        onClick={onClickCancel}
        title={t('common:title.of.cancel', {
          value: t('domain:model.order.title'),
        })}
      />
      <SideMenuLayoutContentLayout
        header={<SideMenuLayoutBreadcrumb items={breadcrumbItems} />}
      >
        <FixedContentLayout>
          <FormProvider {...formMethods}>
            <PrimaryModal
              actionText={t('common:action.positive.save')}
              bodyChildren={
                <Text whiteSpace="pre-wrap">
                  {t('common:description.update', {
                    value: t('trader:shared.nfc_order'),
                  })}
                </Text>
              }
              closeText={t('common:action.negative.cancel')}
              disclosure={updateDisclosure}
              isCentered
              isLoading={isLoadingUpdate}
              onClick={formMethods.handleSubmit(onSubmit)}
              title={t('common:title.of.update', {
                value: t('domain:model.order.title'),
              })}
            />
            <FormErrorAlert
              error={formMethods.formState.errors}
              scrollRef={scrollElementRef}
            />
            <OrderInformation />
            <OrderForm formMethods={formMethods} />
            <Flex justifyContent="space-between">
              {isActive && (
                <DangerButton onClick={cancelDisclosure.onOpen}>
                  {t('common:action.negative.revocation')}
                </DangerButton>
              )}
              <Flex justifyContent="flex-end" gap={Spacing[4]} width="full">
                <TertiaryButton
                  onClick={(): void => navigate(PATH_HISTORY.path)}
                >
                  {t('common:action.negative.back')}
                </TertiaryButton>
                {isActive && (
                  <PrimaryButton
                    isLoading={isLoadingUpdate}
                    onClick={formMethods.handleSubmit(
                      updateDisclosure.onOpen,
                      handleError,
                    )}
                  >
                    {t('common:action.neutral.check')}
                  </PrimaryButton>
                )}
              </Flex>
            </Flex>
          </FormProvider>
        </FixedContentLayout>
      </SideMenuLayoutContentLayout>
    </>
  )
}

export default React.memo(OrderDetailPage)
