import * as React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { Spacing, Typography, useTheme } from '@enechain/ecloud-designsystem'
import { GeneratorType } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'

import GeneratorIcon from '../GeneratorIcon'
import GeneratorTypesTooltip from '../GeneratorTypesTooltip'

type Props = {
  generatorTypes: GeneratorType[]
}

const GeneratorTypesLabel: React.FC<Props> = ({ generatorTypes }) => {
  const theme = useTheme()

  if (generatorTypes.length === 1) {
    const generatorType = generatorTypes[0]
    return (
      <Flex gap={Spacing[1]}>
        <GeneratorIcon type={generatorType.type} />
        <Text
          color={theme.semanticTokens.colors.Text.highEmphasis}
          {...Typography.textMd}
        >
          {generatorType.displayName}
        </Text>
      </Flex>
    )
  }
  return (
    <GeneratorTypesTooltip>
      <Flex gap={Spacing[1]} overflow="hidden" whiteSpace="nowrap">
        {generatorTypes.map((generatorType) => (
          <GeneratorIcon key={generatorType.type} type={generatorType.type} />
        ))}
      </Flex>
    </GeneratorTypesTooltip>
  )
}

export default React.memo(GeneratorTypesLabel)
