import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import { ChangeBadge } from '~/trader/pages/CarbonIndex/components/ChangeBadge'
import { LatestPriceHeader } from '~/trader/pages/CarbonIndex/components/LatestPriceHeader'
import { TypeLabel } from '~/trader/pages/CarbonIndex/components/TypeLabel'
import { NFCGreenIndexSummaryModel } from '~/trader/pages/CarbonIndex/models/indexSummary/nfc'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  data: Maybe<NFCGreenIndexSummaryModel>
}

export const NFCPriceContents: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(['common', 'domain', 'trader'])

  if (data === undefined) {
    return null
  }

  const { averagePrices, latestPrices, latestReferenceDate } = data

  return (
    <>
      <ColumnContent
        flexSize={0}
        label={t('trader:page.carbon_index.title_average_price')}
      >
        <Flex
          gap={Spacing[4]}
          overflowX="scroll"
          paddingBottom={Spacing[2]}
          whiteSpace="nowrap"
        >
          {averagePrices.length === 0 && (
            <Text>{t('common:error.empty_data')}</Text>
          )}
          {averagePrices.map((averagePrice) => (
            <Box
              key={`${averagePrice.productType.type}-${averagePrice.deliveryFiscalYear}`}
            >
              <TypeLabel
                deliveryFiscalYear={averagePrice.deliveryFiscalYear}
                value={averagePrice.productType}
              />
              <Text {...Typography.headerLg}>{averagePrice.price}</Text>
            </Box>
          ))}
        </Flex>
      </ColumnContent>
      <Stack spacing={Spacing[2]}>
        <LatestPriceHeader date={latestReferenceDate} />
        <Flex
          gap={Spacing[4]}
          overflowX="scroll"
          paddingBottom={Spacing[2]}
          whiteSpace="nowrap"
        >
          {latestPrices.length === 0 && (
            <Text>{t('common:error.empty_data')}</Text>
          )}
          {latestPrices.map((latestPrice) => {
            return (
              <Box
                key={`${latestPrice.productType.type}-${latestPrice.deliveryFiscalYear}`}
              >
                <TypeLabel
                  deliveryFiscalYear={latestPrice.deliveryFiscalYear}
                  value={latestPrice.productType}
                />
                <Text {...Typography.headerLg}>{latestPrice.price}</Text>
                <ChangeBadge
                  changeAmount={latestPrice.changeAmount.toString()}
                  changeRate={latestPrice.changeRate}
                  isNull={latestPrice.isNull}
                />
              </Box>
            )
          })}
        </Flex>
      </Stack>
    </>
  )
}
