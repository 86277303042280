import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { DSSemanticColors, appColors } from '.'

const helpers = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const iconColor = {
  background: appColors.natureGreen,
  backgroundColor: appColors.natureGreen,
  borderColor: appColors.natureGreen,
}

const baseStyle = helpers.definePartsStyle({
  control: {
    _checked: {
      ...iconColor,
      _hover: iconColor,
    },
    _indeterminate: iconColor,
    _pressed: iconColor,
    _hover: { borderColor: appColors.natureGreen },
  },
  icon: {
    _checked: {
      ...iconColor,
      _hover: iconColor,
      _disabled: {
        background: DSSemanticColors.Object.disabled,
        backgroundColor: DSSemanticColors.Object.disabled,
      },
    },
  },
})

export default helpers.defineMultiStyleConfig({ baseStyle })
