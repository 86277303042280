import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Collapse, Flex, HStack, Stack, Text } from '@chakra-ui/react'

import { Spacing, Typography, useTheme } from '@enechain/ecloud-designsystem'
import { FaSlidersRegular } from '@enechain/ecloud-icons'

import { Card, ClearLink } from '../../common'

type Props = {
  children: React.ReactNode
  isFilterEmpty?: boolean
  clear?: () => void
}

const FilterContent: React.FC<Props> = ({ children, isFilterEmpty, clear }) => {
  const theme = useTheme()
  const { t } = useTranslation('component')

  return (
    <Stack gap={Spacing[2]}>
      <Flex alignItems="center" justifyContent="space-between" width="full">
        <HStack
          alignItems="center"
          paddingLeft={Spacing[2]}
          color={theme.semanticTokens.colors.Text.highEmphasis}
        >
          <FaSlidersRegular width="24px" height="24px" />
          <Text {...Typography.headerSm} height="auto" wordBreak="break-word">
            {t('layouts.filter_content.title')}
          </Text>
        </HStack>
      </Flex>
      <Card
        borderColor="transparent"
        backgroundColor={theme.semanticTokens.colors.Surface.secondary}
        bodyProps={{
          p: Spacing[6],
          display: 'flex',
          flexDirection: 'column',
          gap: Spacing[4],
        }}
      >
        {children}
        <Collapse
          animateOpacity
          in={isFilterEmpty !== true && clear !== undefined}
        >
          {clear !== undefined && (
            <ClearLink
              clear={clear}
              label={t('layouts.filter_content.action')}
            />
          )}
        </Collapse>
      </Card>
    </Stack>
  )
}

export default React.memo(FilterContent)
