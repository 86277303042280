import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'

import {
  SideMenuLayoutMenuItem,
  SideMenuLayoutOrganization,
} from '@enechain/ecloud-designsystem'
import {
  FaChartLineRegular,
  FaClockRotateLeftRegular,
  FaFileChartColumnRegular,
  FaFileSpreadsheetSolid,
  FaRightLeftRegular,
} from '@enechain/ecloud-icons'
import { REPORT, isActiveFeature } from '@enechain/jcex-libs'
import { MainLayoutLoaderData } from '@enechain/jcex-ui'

import {
  PATH_CARBON_INDEX,
  PATH_CREATE_ORDER,
  PATH_HISTORY,
  PATH_HISTORY_NFC_ORDER,
  PATH_INSTITUTION,
  PATH_NEWSLETTER,
  PATH_ORDER_BOOK,
} from '~/trader/routes/routePaths'

const orderBookPageSelectedPaths = [
  PATH_ORDER_BOOK.path,
  PATH_CREATE_ORDER.path,
]

const historyPageSelectedPaths = [
  PATH_HISTORY.path,
  PATH_HISTORY_NFC_ORDER.path,
]

export const MainLayoutTopMenu: React.FC = () => {
  const { t } = useTranslation('trader')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuth0()

  const { name: orgName, src: orgImgSrc } =
    useLoaderData() as MainLayoutLoaderData

  return (
    <>
      <SideMenuLayoutOrganization name={orgName} src={orgImgSrc} />
      <SideMenuLayoutMenuItem
        IconLeft={FaRightLeftRegular}
        isActive={orderBookPageSelectedPaths.includes(pathname)}
        label={PATH_ORDER_BOOK.name}
        onClick={(): void => {
          navigate(PATH_ORDER_BOOK.path)
        }}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaClockRotateLeftRegular}
        isActive={historyPageSelectedPaths.some((path) =>
          pathname.includes(path),
        )}
        label={PATH_HISTORY.name}
        onClick={(): void => {
          navigate(PATH_HISTORY.path)
        }}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaChartLineRegular}
        isActive={pathname.includes(PATH_CARBON_INDEX.path)}
        label={t('shared.carbon_index')}
        onClick={(): void => {
          navigate(PATH_CARBON_INDEX.path)
        }}
      />
      {isActiveFeature({ featureName: REPORT, user }) && (
        <SideMenuLayoutMenuItem
          IconLeft={FaFileChartColumnRegular}
          isActive={pathname.includes(PATH_INSTITUTION.path)}
          label="審議会レポート"
          onClick={(): void => {
            navigate(PATH_INSTITUTION.path)
          }}
        />
      )}
      {isActiveFeature({ featureName: REPORT, user }) && (
        <SideMenuLayoutMenuItem
          IconLeft={FaFileSpreadsheetSolid}
          isActive={pathname.includes(PATH_NEWSLETTER.path)}
          label="ニュースレター"
          onClick={(): void => {
            navigate(PATH_NEWSLETTER.path)
          }}
        />
      )}
    </>
  )
}
