import { ListNewslettersForCustomerResponse } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/service_pb'

import {
  CustomerReportModel,
  convertCustomerReportModelList,
} from '~/trader/models/customerReport'
import { Maybe } from '~/trader/types/AdvancedTypes'

export type NewsletterModel = CustomerReportModel

export function convertNewsletterModelList(
  data: Maybe<ListNewslettersForCustomerResponse>,
): NewsletterModel[] {
  if (data === undefined) {
    return []
  }

  return convertCustomerReportModelList(data.newsletters)
}
