// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=ts"
// @generated from file jcex/bff/report/v1/service.proto (package jcex.bff.report.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateAssessmentSettingRequest, CreateAssessmentSettingResponse, CreateInstitutionReportRequest, CreateInstitutionReportResponse, CreateNewsletterRequest, CreateNewsletterResponse, DeleteAssessmentSettingRequest, DeleteAssessmentSettingResponse, DeleteInstitutionReportRequest, DeleteInstitutionReportResponse, DeleteNewsletterRequest, DeleteNewsletterResponse, DetermineAssessmentSettingResultsRequest, DetermineAssessmentSettingResultsResponse, GetAssessmentJCreditResultRequest, GetAssessmentJCreditResultResponse, GetAssessmentNfcResultRequest, GetAssessmentNfcResultResponse, GetAssessmentSettingRequest, GetAssessmentSettingResponse, GetDenkiShimbunSubmissionTsvRequest, GetDenkiShimbunSubmissionTsvResponse, GetGreenIndexRequest, GetGreenIndexResponse, GetInstitutionReportDownloadSignedUrlForCustomerRequest, GetInstitutionReportDownloadSignedUrlForCustomerResponse, GetInstitutionReportDownloadSignedUrlForOpsRequest, GetInstitutionReportDownloadSignedUrlForOpsResponse, GetInstitutionReportUploadStatusRequest, GetInstitutionReportUploadStatusResponse, GetNewsletterDownloadSignedUrlForCustomerRequest, GetNewsletterDownloadSignedUrlForCustomerResponse, GetNewsletterDownloadSignedUrlForOpsRequest, GetNewsletterDownloadSignedUrlForOpsResponse, GetNewsletterUploadStatusRequest, GetNewsletterUploadStatusResponse, ListInstitutionReportsForCustomerRequest, ListInstitutionReportsForCustomerResponse, ListInstitutionReportsForOpsRequest, ListInstitutionReportsForOpsResponse, ListNewslettersForCustomerRequest, ListNewslettersForCustomerResponse, ListNewslettersForOpsRequest, ListNewslettersForOpsResponse, UpdateAssessmentJCreditResultSourceRequest, UpdateAssessmentJCreditResultSourceResponse, UpdateAssessmentNfcResultSourceRequest, UpdateAssessmentNfcResultSourceResponse } from "./service_pb.js";

/**
 * アセスメント設定の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentSetting
 */
export const getAssessmentSetting = {
  localName: "getAssessmentSetting",
  name: "GetAssessmentSetting",
  kind: MethodKind.Unary,
  I: GetAssessmentSettingRequest,
  O: GetAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の作成
 *
 * @generated from rpc jcex.bff.report.v1.Service.CreateAssessmentSetting
 */
export const createAssessmentSetting = {
  localName: "createAssessmentSetting",
  name: "CreateAssessmentSetting",
  kind: MethodKind.Unary,
  I: CreateAssessmentSettingRequest,
  O: CreateAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の削除
 *
 * @generated from rpc jcex.bff.report.v1.Service.DeleteAssessmentSetting
 */
export const deleteAssessmentSetting = {
  localName: "deleteAssessmentSetting",
  name: "DeleteAssessmentSetting",
  kind: MethodKind.Unary,
  I: DeleteAssessmentSettingRequest,
  O: DeleteAssessmentSettingResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [非化石証書] アセスメント結果の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentNfcResult
 */
export const getAssessmentNfcResult = {
  localName: "getAssessmentNfcResult",
  name: "GetAssessmentNfcResult",
  kind: MethodKind.Unary,
  I: GetAssessmentNfcResultRequest,
  O: GetAssessmentNfcResultResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [Jクレジット] アセスメント結果の取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetAssessmentJCreditResult
 */
export const getAssessmentJCreditResult = {
  localName: "getAssessmentJCreditResult",
  name: "GetAssessmentJCreditResult",
  kind: MethodKind.Unary,
  I: GetAssessmentJCreditResultRequest,
  O: GetAssessmentJCreditResultResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [非化石証書]アセスメント計算結果のソース更新
 *
 * @generated from rpc jcex.bff.report.v1.Service.UpdateAssessmentNfcResultSource
 */
export const updateAssessmentNfcResultSource = {
  localName: "updateAssessmentNfcResultSource",
  name: "UpdateAssessmentNfcResultSource",
  kind: MethodKind.Unary,
  I: UpdateAssessmentNfcResultSourceRequest,
  O: UpdateAssessmentNfcResultSourceResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * [Jクレジット]アセスメント計算結果のソース更新
 *
 * @generated from rpc jcex.bff.report.v1.Service.UpdateAssessmentJCreditResultSource
 */
export const updateAssessmentJCreditResultSource = {
  localName: "updateAssessmentJCreditResultSource",
  name: "UpdateAssessmentJCreditResultSource",
  kind: MethodKind.Unary,
  I: UpdateAssessmentJCreditResultSourceRequest,
  O: UpdateAssessmentJCreditResultSourceResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * アセスメント設定の計算結果を確定する
 *
 * @generated from rpc jcex.bff.report.v1.Service.DetermineAssessmentSettingResults
 */
export const determineAssessmentSettingResults = {
  localName: "determineAssessmentSettingResults",
  name: "DetermineAssessmentSettingResults",
  kind: MethodKind.Unary,
  I: DetermineAssessmentSettingResultsRequest,
  O: DetermineAssessmentSettingResultsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 電気新聞への提出用TSVデータの取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetDenkiShimbunSubmissionTsv
 */
export const getDenkiShimbunSubmissionTsv = {
  localName: "getDenkiShimbunSubmissionTsv",
  name: "GetDenkiShimbunSubmissionTsv",
  kind: MethodKind.Unary,
  I: GetDenkiShimbunSubmissionTsvRequest,
  O: GetDenkiShimbunSubmissionTsvResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * グリーンインデックスの取得
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetGreenIndex
 */
export const getGreenIndex = {
  localName: "getGreenIndex",
  name: "GetGreenIndex",
  kind: MethodKind.Unary,
  I: GetGreenIndexRequest,
  O: GetGreenIndexResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートの作成
 * - このAPIは、審議会レポートを作成し、審議会レポートアップロード用の署名付きURLを返却する
 * - 審議会レポートIDを使うことで、GetInstitutionReportUploadStatusでアップロード状況が確認できる
 *
 * @generated from rpc jcex.bff.report.v1.Service.CreateInstitutionReport
 */
export const createInstitutionReport = {
  localName: "createInstitutionReport",
  name: "CreateInstitutionReport",
  kind: MethodKind.Unary,
  I: CreateInstitutionReportRequest,
  O: CreateInstitutionReportResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートのアップロードステータスの取得
 * - フロントエンドは署名付きURLにファイルをアップロード後、このAPIを叩いてアップロード状況を確認する
 * - CreateInstitutionReportから返ってきた有効期限を過ぎたらポーリングを止める
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetInstitutionReportUploadStatus
 */
export const getInstitutionReportUploadStatus = {
  localName: "getInstitutionReportUploadStatus",
  name: "GetInstitutionReportUploadStatus",
  kind: MethodKind.Unary,
  I: GetInstitutionReportUploadStatusRequest,
  O: GetInstitutionReportUploadStatusResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートの削除
 *
 * @generated from rpc jcex.bff.report.v1.Service.DeleteInstitutionReport
 */
export const deleteInstitutionReport = {
  localName: "deleteInstitutionReport",
  name: "DeleteInstitutionReport",
  kind: MethodKind.Unary,
  I: DeleteInstitutionReportRequest,
  O: DeleteInstitutionReportResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートのリストの取得(顧客用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.ListInstitutionReportsForCustomer
 */
export const listInstitutionReportsForCustomer = {
  localName: "listInstitutionReportsForCustomer",
  name: "ListInstitutionReportsForCustomer",
  kind: MethodKind.Unary,
  I: ListInstitutionReportsForCustomerRequest,
  O: ListInstitutionReportsForCustomerResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートのリストの取得(Ops用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.ListInstitutionReportsForOps
 */
export const listInstitutionReportsForOps = {
  localName: "listInstitutionReportsForOps",
  name: "ListInstitutionReportsForOps",
  kind: MethodKind.Unary,
  I: ListInstitutionReportsForOpsRequest,
  O: ListInstitutionReportsForOpsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートダウンロード用の署名付きURLの取得(顧客用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetInstitutionReportDownloadSignedUrlForCustomer
 */
export const getInstitutionReportDownloadSignedUrlForCustomer = {
  localName: "getInstitutionReportDownloadSignedUrlForCustomer",
  name: "GetInstitutionReportDownloadSignedUrlForCustomer",
  kind: MethodKind.Unary,
  I: GetInstitutionReportDownloadSignedUrlForCustomerRequest,
  O: GetInstitutionReportDownloadSignedUrlForCustomerResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * 審議会レポートダウンロード用の署名付きURLの取得(Ops用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetInstitutionReportDownloadSignedUrlForOps
 */
export const getInstitutionReportDownloadSignedUrlForOps = {
  localName: "getInstitutionReportDownloadSignedUrlForOps",
  name: "GetInstitutionReportDownloadSignedUrlForOps",
  kind: MethodKind.Unary,
  I: GetInstitutionReportDownloadSignedUrlForOpsRequest,
  O: GetInstitutionReportDownloadSignedUrlForOpsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターの作成
 * - このAPIは、GXニュースレターを作成し、GXニュースレターアップロード用の署名付きURLを返却する
 * - ニュースレターIDを使うことで、GetNewsletterUploadStatusでアップロード状況が確認できる
 *
 * @generated from rpc jcex.bff.report.v1.Service.CreateNewsletter
 */
export const createNewsletter = {
  localName: "createNewsletter",
  name: "CreateNewsletter",
  kind: MethodKind.Unary,
  I: CreateNewsletterRequest,
  O: CreateNewsletterResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターのアップロードステータスの取得
 * - フロントエンドは署名付きURLにファイルをアップロード後、このAPIを叩いてアップロード状況を確認する
 * - CreateNewsletterから返ってきた有効期限を過ぎたらポーリングを止める
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetNewsletterUploadStatus
 */
export const getNewsletterUploadStatus = {
  localName: "getNewsletterUploadStatus",
  name: "GetNewsletterUploadStatus",
  kind: MethodKind.Unary,
  I: GetNewsletterUploadStatusRequest,
  O: GetNewsletterUploadStatusResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターの削除
 *
 * @generated from rpc jcex.bff.report.v1.Service.DeleteNewsletter
 */
export const deleteNewsletter = {
  localName: "deleteNewsletter",
  name: "DeleteNewsletter",
  kind: MethodKind.Unary,
  I: DeleteNewsletterRequest,
  O: DeleteNewsletterResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターのリストの取得(顧客用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.ListNewslettersForCustomer
 */
export const listNewslettersForCustomer = {
  localName: "listNewslettersForCustomer",
  name: "ListNewslettersForCustomer",
  kind: MethodKind.Unary,
  I: ListNewslettersForCustomerRequest,
  O: ListNewslettersForCustomerResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターのリストの取得(Ops用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.ListNewslettersForOps
 */
export const listNewslettersForOps = {
  localName: "listNewslettersForOps",
  name: "ListNewslettersForOps",
  kind: MethodKind.Unary,
  I: ListNewslettersForOpsRequest,
  O: ListNewslettersForOpsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターダウンロード用の署名付きURLの取得(顧客用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetNewsletterDownloadSignedUrlForCustomer
 */
export const getNewsletterDownloadSignedUrlForCustomer = {
  localName: "getNewsletterDownloadSignedUrlForCustomer",
  name: "GetNewsletterDownloadSignedUrlForCustomer",
  kind: MethodKind.Unary,
  I: GetNewsletterDownloadSignedUrlForCustomerRequest,
  O: GetNewsletterDownloadSignedUrlForCustomerResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;

/**
 * GXニュースレターダウンロード用の署名付きURLの取得(Ops用)
 *
 * @generated from rpc jcex.bff.report.v1.Service.GetNewsletterDownloadSignedUrlForOps
 */
export const getNewsletterDownloadSignedUrlForOps = {
  localName: "getNewsletterDownloadSignedUrlForOps",
  name: "GetNewsletterDownloadSignedUrlForOps",
  kind: MethodKind.Unary,
  I: GetNewsletterDownloadSignedUrlForOpsRequest,
  O: GetNewsletterDownloadSignedUrlForOpsResponse,
  service: {
    typeName: "jcex.bff.report.v1.Service"
  }
} as const;
