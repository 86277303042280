import * as React from 'react'

import { useSideMenuCollapsed } from '@enechain/ecloud-designsystem'
import {
  JcexHorizontalWithYohaku,
  JcexSymbolWithYohaku,
} from '@enechain/ecloud-icons'

export const ProductLogo: React.FC = () => {
  const { collapsed } = useSideMenuCollapsed()

  if (collapsed) {
    return (
      <JcexSymbolWithYohaku
        width="28px"
        height="28px"
        marginTop="-4px"
        marginLeft="-5px"
        aria-hidden
      />
    )
  }
  return (
    <JcexHorizontalWithYohaku
      width="81px"
      height="20px"
      marginLeft="-0.5px"
      aria-hidden
    />
  )
}
