import * as React from 'react'

import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Label,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { Box } from '@chakra-ui/react'

import { useTheme } from '@enechain/ecloud-designsystem'
import { TAB_TYPES, TabKey } from '@enechain/jcex-ui'

import { renderChartLegend } from '~/trader/pages/CarbonIndex/components/ChartLegend'
import { renderChartTooltip } from '~/trader/pages/CarbonIndex/components/ChartTooltip'
import { EmptyChart } from '~/trader/pages/CarbonIndex/components/LineChart/emptyChart'
import { GreenIndexModel } from '~/trader/pages/CarbonIndex/models/greenIndex'

type Props = {
  data: GreenIndexModel
  tab: TabKey
}

const X_KEY = 'referenceDate'

/**
 * グラフの最小値に対して、0.1 下げた値で描画するようにする
 * 価格帯に応じて、変更する必要があるので、ロジックが必要になった際に再検討する
 */
const GRAPH_MIN = 'dataMin - 0.1'

export const LineChart: React.FC<Props> = ({ data, tab }) => {
  const { t } = useTranslation('trader')
  const theme = useTheme()
  const { nfc: nfcData, jCredit: jCreditData } = data

  const renderLines = (
    dataKeys: Array<{
      dataKey: string | number
      color: string
      name: string
    }>,
  ): React.ReactElement => {
    return (
      <>
        {dataKeys.map((item) => (
          <Line
            key={item.dataKey}
            stroke={item.color}
            animationDuration={200}
            connectNulls
            dataKey={item.dataKey}
            dot={false}
            name={item.name}
            type="linear"
          />
        ))}
      </>
    )
  }

  return (
    <Box position="relative">
      {((tab === TAB_TYPES.NFC && nfcData.graph.isEmptyData) ||
        (tab === TAB_TYPES.JCREDIT && jCreditData.graph.isEmptyData)) && (
        <EmptyChart />
      )}
      <ResponsiveContainer height={400}>
        <RechartsLineChart
          margin={{ bottom: 20 }}
          data={
            tab === TAB_TYPES.NFC ? nfcData.graph.data : jCreditData.graph.data
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis angle={-8} dataKey={X_KEY} dx={-10} dy={5}>
            <Label
              position="insideBottom"
              offset={-15}
              value={t('page.carbon_index.display_period')}
            />
          </XAxis>
          <YAxis padding={{ top: 10 }} domain={[GRAPH_MIN, 'auto']} />
          {renderChartLegend({ theme })}
          {renderChartTooltip({ theme, tab })}
          {tab === TAB_TYPES.NFC
            ? renderLines(nfcData.graph.dataKey)
            : renderLines(jCreditData.graph.dataKey)}
        </RechartsLineChart>
      </ResponsiveContainer>
    </Box>
  )
}
