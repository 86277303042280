import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  SegmentedControl,
  Spacing,
  Tooltip,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import { FaCircleQuestionRegular } from '@enechain/ecloud-icons'

import {
  VOLUME_UNIT_TYPES,
  useVolumeUnitMutations,
  useVolumeUnitQueries,
} from '../../../hooks'

export const ChangeVolumeUnitControl: React.FC = () => {
  const { t } = useTranslation(['component', 'common'])
  const theme = useTheme()

  const selectedUnit = useVolumeUnitQueries()
  const { setUnitState } = useVolumeUnitMutations()

  return (
    <Flex alignItems="center" gap={Spacing[4]}>
      <Text
        {...Typography.headerSm}
        color={theme.semanticTokens.colors.Text.highEmphasis}
      >
        {t('component:features.change_volume_unit_control.label')}
      </Text>
      <SegmentedControl
        name="changeVolumeUnit"
        onSelect={(value): void => setUnitState(value)}
        options={[
          {
            label: t(`common:unit.${VOLUME_UNIT_TYPES.KWH}`),
            value: VOLUME_UNIT_TYPES.KWH,
          },
          {
            label: t(`common:unit.${VOLUME_UNIT_TYPES.HUNDRED_MILLION_KWH}`),
            value: VOLUME_UNIT_TYPES.HUNDRED_MILLION_KWH,
          },
          {
            label: (
              <Flex gap={Spacing[1]}>
                <Text>{t(`common:unit.${VOLUME_UNIT_TYPES.MW}`)}</Text>
                <Tooltip
                  label={t(
                    'component:features.change_volume_unit_control.tooltip',
                  )}
                >
                  <FaCircleQuestionRegular width="20px" height="20px" />
                </Tooltip>
              </Flex>
            ),
            value: VOLUME_UNIT_TYPES.MW,
          },
        ]}
        selected={selectedUnit}
      />
    </Flex>
  )
}
