import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { BorderRadius } from '@enechain/ecloud-designsystem'

import { DSSemanticColors, appColors } from '.'

export const radioStyle = {
  borderColor: DSSemanticColors.Border.midEmphasis,
  _hover: {
    borderColor: appColors.natureGreen,
    backgroundColor: DSSemanticColors.Surface.primary,
  },
  _checked: {
    borderColor: appColors.natureGreen,
    backgroundColor: DSSemanticColors.Surface.primary,
    _hover: {
      borderColor: appColors.natureGreen,
      backgroundColor: DSSemanticColors.Surface.primary,
    },
    _before: {
      content: `""`,
      position: 'absolute',
      borderRadius: BorderRadius.radiiHalf,
      width: '8px',
      height: '8px',
      backgroundColor: appColors.natureGreen,
    },
  },
} as const

const helpers = createMultiStyleConfigHelpers(radioAnatomy.keys)

const variants = {
  inline: helpers.definePartsStyle({
    control: radioStyle,
  }),
}
export default helpers.defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: 'inline',
  },
})
