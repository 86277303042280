import * as React from 'react'

import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RadioProps, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  Label,
  RadioButton,
  Spacing,
  Term,
  Typography,
  useTheme,
} from '@enechain/ecloud-designsystem'
import {
  Position_Type,
  ProductType_Type,
} from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import {
  CardLayout,
  CertifiedNumberTooltip,
  NumberInputWithComma,
  PrefectureForm,
  SelectBox,
} from '@enechain/jcex-ui'
import { radioStyle } from '@enechain/jcex-ui/src/constants/radio'

import { CreditCertifiedForm } from '~/trader/components/features/CreditCertifiedForm'
import { J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH } from '~/trader/constants/jCredit'
import { isOrderActive } from '~/trader/models/orderStatus'
import { useOrderDetail } from '~/trader/pages/JCreditOrderDetail/hooks/useOrderDetail'
import { useUpdateOrderOption } from '~/trader/pages/JCreditOrderDetail/hooks/useUpdateOrderOption'
import { UpdateOrderSchema } from '~/trader/pages/JCreditOrderDetail/schemas/updateOrderSchema'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  formMethods: UseFormReturn<UpdateOrderSchema>
}

const OrderForm: React.FC<Props> = ({ formMethods }) => {
  const theme = useTheme()
  const { t } = useTranslation(['common', 'domain', 'trader'])
  const { data } = useOrderDetail()
  const { options } = useUpdateOrderOption()

  const watchedPositionType = useWatch({
    name: 'positionType',
    control: formMethods.control,
  })
  const watchedPrefectureIds = formMethods.watch('prefectureIds')

  const isOffer = watchedPositionType === Position_Type.OFFER
  const isActive = isOrderActive(data?.orderInformation?.orderStatus)
  const isCertificatedLength =
    !isOffer ||
    formMethods.watch('creditCertifiedNumber')?.length ===
      J_CREDIT_CREDIT_CERTIFIED_NUMBER_LENGTH

  return (
    <CardLayout title={t('trader:shared.product_information')}>
      <Stack spacing={Spacing[6]}>
        <ColumnContent label={t('domain:shared.product_type')}>
          <Text
            color={theme.semanticTokens.colors.Text.highEmphasis}
            {...Typography.textMd}
          >
            {t('domain:shared.j_credit')}
          </Text>
        </ColumnContent>
        <Controller
          control={formMethods.control}
          disabled={!isActive}
          name="positionType"
          render={({ field: { onChange, value } }): React.ReactElement => (
            <Term>
              <Label required>{t('domain:shared.position.title')}</Label>
              <RadioButton
                isDisabled={!isActive}
                onChange={(value): void => onChange(Number(value))}
                radios={options.positions.map(
                  ({ type, displayName }) =>
                    // todo: RadioPropsの型定義を import できるようになったタイミングで修正
                    ({
                      ...radioStyle,
                      value: type.toString(),
                      label: displayName,
                    } as RadioProps & { label: string }),
                )}
                value={value.toString()}
              />
              <Text color={theme.semanticTokens.colors.Text.negativeMid}>
                {formMethods.formState.errors.positionType?.message}
              </Text>
            </Term>
          )}
        />
        <Controller
          control={formMethods.control}
          disabled={!isActive}
          name="deliveryFiscalYear"
          render={({ field: { value } }): React.ReactElement => (
            <Term>
              <Label required>
                {t('domain:model.order.column.delivery_fiscal_year')}
              </Label>
              <SelectBox<number>
                maxWidth="25rem"
                backgroundColor={theme.semanticTokens.colors.Surface.primary}
                isDisabled={!isActive}
                isRequired
                onChange={(value: Maybe<number>): void =>
                  formMethods.setValue('deliveryFiscalYear', Number(value))
                }
                selectable={Object.fromEntries(
                  options.deliveryFiscalYears.length > 0
                    ? options.deliveryFiscalYears.map(
                        ({ year, displayName }) => [Number(year), displayName],
                      )
                    : [],
                )}
                selectedValue={value}
              />
              <Text color={theme.semanticTokens.colors.Text.negativeMid}>
                {formMethods.formState.errors.deliveryFiscalYear?.message}
              </Text>
            </Term>
          )}
        />
        {isOffer && (
          <Controller
            control={formMethods.control}
            disabled={!isActive}
            name="creditCertifiedNumber"
            render={({ field: { onChange, value } }): React.ReactElement => (
              <CreditCertifiedForm
                error={
                  formMethods.formState.errors.creditCertifiedNumber?.message
                }
                initialCertification={data?.jCreditCertification}
                isDisabled={!isActive}
                onChange={(value): void => {
                  onChange(value.creditCertifiedNumber)
                  formMethods.setValue('productId', value.productId)
                  formMethods.setValue('prefectureIds', value.prefectureIds)
                  formMethods.setValue(
                    'jCreditCertificationId',
                    value.certificationId,
                  )
                }}
                selectedCertificationId={formMethods.watch(
                  'jCreditCertificationId',
                )}
                value={value}
              />
            )}
          />
        )}
        <CertifiedNumberTooltip isHidden={isCertificatedLength}>
          <Controller
            control={formMethods.control}
            disabled={!isActive}
            name="productId"
            render={({ field: { onChange } }): React.ReactElement => (
              <Term>
                <Label required>
                  {t('domain:model.order.column.j_credit.product')}
                </Label>
                <SelectBox
                  maxWidth="25rem"
                  backgroundColor={theme.semanticTokens.colors.Surface.primary}
                  isDisabled={!isActive || !isCertificatedLength}
                  onChange={(value: Maybe<number>): void =>
                    onChange(value !== undefined ? Number(value) : undefined)
                  }
                  selectable={Object.fromEntries(
                    options.products.length > 0
                      ? options.products.map(({ id, name }) => [id, name])
                      : [],
                  )}
                  selectedValue={formMethods.watch('productId')}
                />
                <Text color={theme.semanticTokens.colors.Text.negativeMid}>
                  {formMethods.formState.errors.productId?.message}
                </Text>
              </Term>
            )}
          />
        </CertifiedNumberTooltip>
        <CertifiedNumberTooltip isHidden={isCertificatedLength}>
          <PrefectureForm
            error={formMethods.formState.errors.prefectureIds?.message}
            isDisabled={!isActive || !isCertificatedLength}
            onChange={(value): void => {
              formMethods.setValue('prefectureIds', value)
            }}
            options={options.prefectures}
            productType={ProductType_Type.J_CREDIT}
            selectedPositionType={Position_Type.BID}
            values={watchedPrefectureIds ?? []}
          />
        </CertifiedNumberTooltip>
        <Controller
          control={formMethods.control}
          disabled={!isActive}
          name="unitPrice"
          render={({ field: { onChange } }): React.ReactElement => (
            <Term>
              <Label required>{t('domain:shared.unit_price.t_co2')}</Label>
              <NumberInputWithComma
                error={formMethods.formState.errors.unitPrice?.message}
                isDisabled={!isActive}
                onChange={onChange}
                value={formMethods.watch('unitPrice')}
              />
            </Term>
          )}
        />
        <Controller
          control={formMethods.control}
          disabled={!isActive}
          name="volume"
          render={({ field: { onChange } }): React.ReactElement => (
            <Term>
              <Label required>{t('domain:shared.volume.t_co2')}</Label>
              <NumberInputWithComma
                error={formMethods.formState.errors.volume?.message}
                isDisabled={!isActive}
                onChange={onChange}
                value={formMethods.watch('volume')}
              />
            </Term>
          )}
        />
      </Stack>
    </CardLayout>
  )
}

export default OrderForm
