import * as React from 'react'

import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AlertDescription, AlertTitle, Box } from '@chakra-ui/react'

import { Alert } from '@enechain/ecloud-designsystem'

import type validation from '../../../../../i18n/libs/locales/ja/validation.json'

type Props = {
  error?: FieldErrors
  scrollRef?: React.RefObject<HTMLDivElement>
}

const FormErrorAlert: React.FC<Props> = ({ error, scrollRef }) => {
  const { t } = useTranslation()

  const validKey = (key: string): string => {
    // memo:
    // 引数で受け取る key は json の key を期待するので、型互換のためにアサーションしている
    // useTranslation 内の t が受け取る key の型戻り値に namespace を加えることで合わせている
    // https://github.com/i18next/i18next/blob/f2a6b6c988a325f97766f74a6e9ea39fbc3b0ea8/typescript/t.d.ts#L57
    const assertKey = `validation:${key}` as unknown as typeof validation
    return t(assertKey)
  }

  if (error === undefined || Object.keys(error).length === 0) {
    return <div ref={scrollRef} />
  }
  return (
    <Alert ref={scrollRef} status="error">
      <Box whiteSpace="pre-wrap">
        <AlertTitle>{t('component:common.form_error_alert.title')}</AlertTitle>
        <AlertDescription>
          {t('component:common.form_error_alert.description')}
          <br />
        </AlertDescription>
        {Object.entries(error).map(([key, value]) => {
          if (value?.message === undefined) {
            return null
          }
          return (
            <AlertDescription key={key}>
              {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
              {`・ ${validKey(key)}: ${value.message.toString()}\n`}
            </AlertDescription>
          )
        })}
      </Box>
    </Alert>
  )
}

export default React.memo(FormErrorAlert)
